import React, {useState} from "react";
import Input from "../../../components/Inputs/Input";
import Checkbox from "../../../components/Inputs/Checkbox";
import toast from "react-hot-toast";
import {InsertCarType} from "../../../services/CarService";
import Logging from "../../../utils/Logging";

export default function AddCarType() {
    const [type, setType] = useState<CarType>({id: 0, name: "", isActive: false} as CarType);
    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log(type)
        InsertCarType(type)
            .then((res) => {
                console.log(res);
                if (res.status === 201) {
                    toast.success(res.message)
                } else {
                    toast.error(res.message);
                }
            })
            .catch(e => Logging.error(e));
    }

    return (
        <div className="card p-4  rounded-5">
            <div className="row">
                <div className="col-lg-12">
                    <form id="form-create-item" className="form-border" onSubmit={handleSubmit}
                          encType='multipart/form-data'>
                        <div className="de_tab tab_simple">

                            <ul className="de_nav">
                                <li className="active"><span>{type.name} (#{type.id})</span></li>
                            </ul>

                            <div className="de_tab_content">
                                <div className="tab-1">
                                    <div className="row">
                                        <div className="col-lg-6 mb20">
                                            <Input id='name' name='name' label='Adı'
                                                   value={type?.name} type='text'
                                                   onChange={(e: any) => setType({
                                                       ...type,
                                                       name: e.target.value
                                                   })}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-sm-20">
                                        <Checkbox
                                            label='Saytda görünsün'
                                            id="notif-item-sold"
                                            name='isActive'
                                            checked={type?.isActive}
                                            onChange={(e: any) => setType({...type, isActive: e.target.checked})}
                                            infoText='Bu tipin saytdan görünüb görünməməsini təmin edir.'/>
                                        <div className="spacer-20"></div>
                                    </div>
                                    <div className="spacer-20"></div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" id="submit" className="btn-main">Tamamla</button>
                    </form>
                </div>
            </div>
        </div>
    );
}