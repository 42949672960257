import { Select } from "@mantine/core";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";
import { garageSelector, getGarages } from "../../redux/stores/GarageReducer";
import toast from "react-hot-toast";
import { InsertOrder } from "../../services/OrderService";
import { useTranslation } from "react-i18next";

type Props = {
  car: Car;
  garages: Garage[];
};

export default function BookForm({ car, garages }: Props) {
  const { t } = useTranslation();
  const [address, setAddress] = useState<string | null>(null);
  const [fullName, setFullName] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [day, setDay] = useState<number>(1);
  const [date, setDate] = useState<string>("");
  const dispatch = useAppDispatch();

  const sendOrder = async (e: any) => {
    e.preventDefault();
    const order = {
      carId: car.id,
      dayCount: day,
      customerName: fullName,
      customerEmail: mail,
      customerPhone: phone,
      pickupPoint: address,
      pickupDate: date,
      orderState: 1,
      orderDate: new Date(),
    };

    await toast.promise(InsertOrder(order as Order), {
      loading: t("book_form.loading"),
      success: <b>{t("book_form.success")}</b>,
      error: <b>{t("book_form.error")}</b>,
    });
  };
  return (
    // <div className='de-box'>
    <form onSubmit={sendOrder}>
      <h4>{t("book_form.order_this")}</h4>

      <div className='spacer-10'></div>

      <div className='row'>
        <div className='col-lg-6 mb20'>
          <h5>{t("book_form.pickup_place")}</h5>
          <Select
            data={garages.map((garage) => garage.address)}
            onChange={setAddress}
          />
        </div>
        <div className='col-lg-4 mb20'>
          <h5>{t("book_form.pickup_date")}</h5>
          <input
            type='datetime-local'
            className='form-control'
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>
        <div className='col-lg-2 mb20'>
          <h5>{t("book_form.pickup_day")}</h5>
          <input
            type='text'
            className='form-control'
            value={day}
            onChange={(e) => {
              setDay(parseInt(e.target.value));
            }}
          />
        </div>
        <div className='col-lg-12 mb20'>
          <h5>{t("book_form.full_name")}</h5>
          <input
            type='text'
            className='form-control'
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
          />
        </div>
        <div className='col-lg-6 mb20'>
          <h5>{t("book_form.phone")}</h5>
          <input
            type='phone'
            className='form-control'
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>
        <div className='col-lg-6 mb20'>
          <h5>{t("book_form.email")}</h5>
          <input
            type='email'
            className='form-control'
            value={mail}
            onChange={(e) => {
              setMail(e.target.value);
            }}
          />
        </div>
      </div>

      <button className='btn-main btn-fullwidth' type='submit'>
        {t("book_form.order_now")}
      </button>

      <div className='clearfix'></div>
    </form>
    //</div>
  );
}
