import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import { useAppDispatch, useAppSelector } from "../../../utils/Hooks";
import { Helmet } from "react-helmet";
import {
  getServices,
  serviceSelector,
} from "../../../redux/stores/ServiceReducer";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Services() {
  const { t } = useTranslation();
  const { services, loading, error } = useAppSelector(serviceSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    function fetchData() {
      dispatch(getServices());
    }

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>RegesRent.Az {t("services_page.title")}</title>
      </Helmet>

      <div className='no-bottom no-top' id='content'>
        <div id='top'></div>
        <section id='subheader' className='jarallax text-light'>
          {/*<img src="/images/background/subheader.webp" className="jarallax-img" alt=""/>*/}
          <div className='center-y relative text-center'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12 text-center '>
                  <h1 className='text-black'>{t("services_page.title")}</h1>
                </div>
                <div className='clearfix'></div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container'>
            <div className='row g-5'>
              <div className='col-lg-12'>
                {error === null ? (
                  loading === true ? (
                    <LoadingGearSpinner />
                  ) : (
                    <ul>
                      {services?.map((service) => (
                        <li key={service.id}>
                          <b>{service.name}</b>
                        </li>
                      ))}
                    </ul>
                  )
                ) : (
                  error
                )}
              </div>
            </div>
            <div className='spacer-double'></div>
          </div>
        </section>
      </div>
    </>
  );
}
