import React, {useState} from "react";
import Input from "../../../components/Inputs/Input";
import Checkbox from "../../../components/Inputs/Checkbox";
import toast from "react-hot-toast";
import {InsertGarage} from "../../../services/CarService";
import Logging from "../../../utils/Logging";

export default function AddGarage() {
    const [garage, setGarage] = useState<Garage>({id: 0, name: "", isActive: false, address: ""} as Garage);
    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log(garage)
        InsertGarage(garage)
            .then((res) => {
                console.log(res);
                if (res.status === 201) {
                    toast.success(res.message)
                } else {
                    toast.error(res.message);
                }
            })
            .catch(e => Logging.error(e));
    }

    return (<>
        <div className="card p-4  rounded-5">
            <div className="row">
                <div className="col-lg-12">
                    <form id="form-create-item" className="form-border" onSubmit={handleSubmit}
                          encType='multipart/form-data'>
                        <div className="de_tab tab_simple">

                            <ul className="de_nav">
                                <li className="active"><span>{garage.name} (#{garage.id})</span></li>
                            </ul>

                            <div className="de_tab_content">
                                <div className="tab-1">
                                    <div className="row">
                                        <div className="col-lg-6 mb20">
                                            <Input id='name' name='name' label='Adı'
                                                   value={garage?.name} type='text'
                                                   onChange={(e: any) => setGarage({
                                                       ...garage,
                                                       name: e.target.value
                                                   })}/>
                                        </div>
                                        <div className="col-lg-6 mb20">
                                            <Input id='name' name='name' label='Ünvan'
                                                   value={garage?.address} type='text'
                                                   onChange={(e: any) => setGarage({
                                                       ...garage,
                                                       address: e.target.value
                                                   })}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-sm-20">
                                        <Checkbox
                                            label='Saytda görünsün'
                                            id="notif-item-sold"
                                            name='isActive'
                                            checked={garage?.isActive}
                                            onChange={(e: any) => setGarage({...garage, isActive: e.target.checked})}
                                            infoText='Bu tipin saytdan görünüb görünməməsini təmin edir.'/>
                                        <div className="spacer-20"></div>
                                    </div>
                                    <div className="spacer-20"></div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" id="submit" className="btn-main">Tamamla</button>
                    </form>
                </div>
            </div>
        </div>
    </>);
}