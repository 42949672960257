import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../utils/Hooks";
import {carSelector, getTypes,} from "../../../redux/stores/CarReducer";
import {Link} from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import toast from "react-hot-toast";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import {DeleteCarType} from "../../../services/CarService";
import Logging from "../../../utils/Logging";

export default function CarTypesTable() {
    const {types, loading, error} = useAppSelector(carSelector);
    const dispatch = useAppDispatch();

    const MySwal = withReactContent(Swal);

    const deleteHandler = (id: number) => {
        MySwal.fire({
            title: "Silmək istədiyinizdən əminsinizmi?",
            showCancelButton: true,
            cancelButtonText: "Xeyr",
            cancelButtonColor: "#f93154",
            confirmButtonColor: "#00b74a",
            confirmButtonText: "Bəli",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteCarType(id).then((res) => {
                    if (res.status === 200) {
                        toast.success(res.message)
                    } else {
                        toast.error(res.message);
                    }
                })
                    .catch(e => Logging.error(e));
            }
        })
    }

    useEffect(() => {
        function fetchData() {
            dispatch(getTypes());
        }

        fetchData();
    }, []);
    return (
        <>
            <div className="card p-4 rounded-5 mb25">
                <h4>Avtomobil növləri</h4>
                <Link to={'/admin/add-type/'} className='btn btn-success'>Yenisini əlavə et</Link>
                {
                    error !== null ? error :
                        loading === true ? <LoadingGearSpinner/> :
                            <table className="table table-hover de-table">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">ID</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Adı</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Göstər/Gizlə</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Əməliyyatlar</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    types?.map((type) => {
                                        return (<tr key={type?.id}>
                                            <td>
                                                <span className="d-lg-none d-sm-block">ID</span>
                                                <div className="badge bg-gray-100 text-dark">#{type?.id}</div>
                                            </td>
                                            <td>
                                                <span className="d-lg-none d-sm-block">Adı</span>
                                                <span className="bold">{type?.name}</span>
                                            </td>
                                            <td>
                                                <span className="d-lg-none d-sm-block">Göster/Gizlə</span>
                                                {type?.isActive ? "Gösənir" : "Görsənmir"}
                                            </td>
                                            <td>
                                                <Link to={'/admin/edit-type/' + type?.id}
                                                      className="btn btn-sm badge rounded-pill bg-success">Düzəlt</Link>
                                                <button className="btn btn-sm badge rounded-pill bg-danger ml10"
                                                        onClick={(e) => deleteHandler(type?.id)}>Sil
                                                </button>
                                            </td>
                                        </tr>)
                                    })
                                }
                                </tbody>
                            </table>
                }
            </div>
        </>
    );
}