import { useParams } from "react-router-dom";
import BookForm from "../../../components/Main/BookForm";
import { useAppDispatch, useAppSelector } from "../../../utils/Hooks";
import { carSelector, getCarById } from "../../../redux/stores/CarReducer";
import { useEffect, useRef } from "react";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import ZoomableSlider from "../../../components/Main/ZoomableSlider";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { Product } from "schema-dts";
import { settingSelector } from "../../../redux/stores/SiteReducer";
import {
  garageSelector,
  getGarages,
} from "../../../redux/stores/GarageReducer";
import { useTranslation } from "react-i18next";

export default function CarDetail() {
  const { t } = useTranslation();
  const { id } = useParams();

  const { car, loading, error } = useAppSelector(carSelector);
  const dispatch = useAppDispatch();
  const [opened, { open, close }] = useDisclosure(false);

  const { setting } = useAppSelector(settingSelector);
  const { garages } = useAppSelector(garageSelector);

  const carSectionTop = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    function fetchData() {
      dispatch(getCarById(id as string));
      dispatch(getGarages());
    }

    fetchData();
    carSectionTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [id]);

  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp<Product>({
            "@context": "https://schema.org",
            "@type": "Product",
            name: car?.name,
            alternateName: car?.name,
            brand: car?.manufacture,
            description: car?.about,
            image: car?.images?.map((img) => img.url),
            url: "https://regesrent.az/car/" + car?.id,
            offers: {
              "@type": "Offer",
              priceCurrency: "AZN",
              price: car?.price,
              availability: "https://schema.org/InStock",
              priceValidUntil: "",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "5",
              reviewCount: "1",
            },
            review: {
              "@type": "Review",
              author: "Alici",
              datePublished: "2023-11-18",
              reviewBody: "Naxçıvanda ən yaxşı kirayə maşın xidməti.",
              name: "Naxçıvanda ən yaxşı kirayə maşın xidməti.",
              reviewRating: {
                "@type": "Rating",
                ratingValue: "5",
                bestRating: "5",
              },
            },
          }),
        ]}
      >
        <title>RegesRent.Az | {car?.name + " " + car?.price}</title>
        <meta
          name='description'
          content={car?.name + " | " + car?.about + "|" + car?.price}
        />
        <meta name='keywords' content={setting?.keywords} />
      </Helmet>

      {error === null ? (
        loading === true ? (
          <LoadingGearSpinner />
        ) : (
          <div className='no-bottom no-top' id='content'>
            <Modal
              opened={opened}
              onClose={close}
              withCloseButton={false}
              size='lg'
              centered
              yOffset={50}
            >
              <BookForm car={car} garages={garages} />
            </Modal>
            <div id='top' ref={carSectionTop}></div>

            <section id='subheader' className='jarallax text-light'>
              <div className='center-y relative text-center'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-9 text-center'>
                      <h1 className='text-black'>{car?.name}</h1>
                    </div>
                    <div className='clearfix'></div>
                  </div>
                </div>
              </div>
            </section>

            <section id='section-car-details'>
              <div className='container'>
                <div className='row g-5'>
                  <div className='col-lg-9'>
                    <div className='row g-5'>
                      <div className='col-lg-12'>
                        {car?.images !== undefined &&
                        car?.images?.length! > 0 ? (
                          <ZoomableSlider
                            images={car?.images.map(({ url }) => url)}
                          />
                        ) : null}
                      </div>

                      <div className='col-lg-12'>
                        <p>{car?.about}</p>

                        <div className='spacer-single'></div>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-3'>
                    <button
                      type='button'
                      className='btn btn-main'
                      style={{ width: "100%", height: 50 }}
                      onClick={open}
                    >
                      {t("detail_page.book_now")}
                    </button>
                    <div className='spacer-10'></div>
                    <div className='de-price text-center'>
                      {t("detail_page.daily_price")}
                      <h3>{car?.price ?? 0}</h3>
                    </div>

                    <div className='spacer-10'></div>
                    <div className='de-price text-center'>
                      {t("detail_page.weekly_price")}
                      <h3>{car?.pricePerHour ?? 0}</h3>
                    </div>

                    <div className='spacer-10'></div>

                    <h4>{t("detail_page.specifications")}</h4>
                    <div className='de-spec'>
                      {car?.specifications?.map(
                        ({ title, value }: Specification, key: any) => {
                          return (
                            <div className='d-row' key={key}>
                              <span className='d-title'>{title}</span>
                              <span className='d-value'>{value}</span>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className='spacer-10'></div>

                    <h4>{t("detail_page.features")}</h4>
                    <div className='de-spec'>
                      <span className='d-atr'>
                        <img
                          style={{ marginRight: 5 }}
                          src='/images/icons/1.svg'
                          alt='person'
                          loading={"lazy"}
                          width={24}
                        />
                        {car?.person} {t("detail_page.person")}
                      </span>
                      <span className='d-atr'>
                        <img
                          style={{ marginRight: 5 }}
                          src='/images/icons/3.svg'
                          alt='door'
                          loading={"lazy"}
                          width={24}
                        />
                        {car?.door} {t("detail_page.door")}
                      </span>
                      <span className='d-atr'>
                        <img
                          style={{ marginRight: 5 }}
                          src='/images/icons/4.svg'
                          alt='type'
                          loading={"lazy"}
                          width={24}
                        />
                        {car?.carType?.name}
                      </span>
                    </div>

                    <div className='spacer-30'></div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )
      ) : (
        error
      )}
    </>
  );
}
