import React, {useEffect, useState} from "react";
import Input from "../../../components/Inputs/Input";
import Checkbox from "../../../components/Inputs/Checkbox";
import SpecificationTable from "../../../components/Admin/SpecificationsTable";
import toast from "react-hot-toast";
import Logging from "../../../utils/Logging";
import {InsertCar} from "../../../services/CarService";
import {useAppDispatch, useAppSelector} from "../../../utils/Hooks";
import {carSelector, getTypes} from "../../../redux/stores/CarReducer";

export default function AddCar() {
    const {types} = useAppSelector(carSelector);
    const dispatch = useAppDispatch();
    const [files, setFiles] = useState<FileList | null>();
    const [state, setState] = useState<Car>({
        id: 0,
        slug: '',
        name: '',
        manufacture: '',
        person: 0,
        door: 0,
        carType: {} as CarType,
        price: 0,
        pricePerHour: 0,
        about: '',
        specifications: [] as Specification[],
        features: [] as Feature[],
        images: [] as Image[],
        isActive: false
    } as Car);
    const handleSubmit = (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        formData.append("specifications", JSON.stringify(state.specifications));
        InsertCar(formData).then((res) => {
            if (res.status === 200) {
                toast.success(res.message)
            } else {
                toast.error(res.message);
            }
        })
            .catch(e => Logging.error(e));

    }

    useEffect(() => {
        function fetchData() {
            dispatch(getTypes())
        }

        fetchData();
    }, [dispatch]);
    return (<>
        <div className="card p-4  rounded-5">
            <div className="row">
                <div className="col-lg-12">
                    <form id="form-create-item" className="form-border" onSubmit={handleSubmit}>
                        <div className="de_tab tab_simple">

                            <ul className="de_nav">
                                <li className="active"><span>Yeni maşın : {state?.name}</span></li>
                            </ul>

                            <div className="de_tab_content">
                                <div className="tab-1">
                                    <div className="row">
                                        <div className="col-lg-6 mb20">
                                            <Input id='name' name='name' label='Adı'
                                                   value={state?.name} type='text' required={false} autoComplete='off'
                                                   onChange={(e: any) => setState({...state, name: e.target.value})}/>
                                        </div>
                                        <div className="col-lg-6 mb20">
                                            <Input id='manufacture' name='manufacture' label='Marka'
                                                   value={state?.manufacture} type='text' required={false}
                                                   autoComplete='off'
                                                   onChange={(e: any) => setState({
                                                       ...state,
                                                       manufacture: e.target.value
                                                   })}/>
                                        </div>
                                        <div className="col-lg-6 mb20">
                                            <Input id='person' name='person' label='Sərnişin sayı'
                                                   value={state?.person} type='number' required={false}
                                                   autoComplete='off'
                                                   onChange={(e: any) => setState({...state, person: e.target.value})}/>
                                        </div>
                                        <div className="col-lg-6 mb20">
                                            <Input id='door' name='door' label='Qapı sayı'
                                                   value={state?.door} type='number' required={false} autoComplete='off'
                                                   onChange={(e: any) => setState({...state, door: e.target.value})}/>
                                        </div>
                                        <div className="col-lg-12 mb20">
                                            <h5>Tip</h5>
                                            <select name='type' id='type' className="form-control">
                                                {
                                                    types?.map((type: CarType) => {
                                                        return <option value={type.id}
                                                                       key={type.id}>{type.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-6 mb20">
                                            <Input id='price' name='price' label='Günlük Qiymət'
                                                   value={state?.price} type='number' required={false}
                                                   autoComplete='off'
                                                   onChange={(e: any) => setState({...state, price: e.target.value})}/>
                                        </div>
                                        <div className="col-lg-6 mb20">
                                            <Input id='pricePerHour' name='pricePerHour' label='Həftəlik Qiymət'
                                                   value={state?.pricePerHour} type='number' required={false}
                                                   autoComplete='off'
                                                   onChange={(e: any) => setState({...state, pricePerHour: e.target.value})}/>
                                        </div>
                                        <div className="col-lg-12 mb20">
                                            <Input id='about' name='about' label='Haqqında'
                                                   value={state.about} type='text' required={false} autoComplete='off'
                                                   onChange={(e: any) => setState({...state, about: e.target.value})}/>
                                        </div>
                                        <div className="col-lg-12 mb20">
                                            <h5>Xüsusiyyətlər</h5>
                                            <SpecificationTable specifications={state?.specifications}
                                                                onChange={(e) => setState({
                                                                    ...state,
                                                                    specifications: e
                                                                })}/>
                                        </div>
                                        <div className="col-lg-12 mb20">
                                            <h5>Şəkillər</h5>
                                            <input type='file' multiple={true} name={'image'} accept="image/png, image/jpeg, image/webp"
                                                   onChange={(e) => setFiles(e?.currentTarget?.files)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-sm-20">
                                        <Checkbox
                                            label='Saytda görünsün'
                                            id="notif-item-sold"
                                            name='isActive'
                                            checked={state?.isActive}
                                            onChange={(e: any) => setState({...state, isActive: e.target.checked})}
                                            infoText='Bu avtomobilin saydan görünüb görünməməsini təmin edir.'/>
                                        <div className="spacer-20"></div>
                                    </div>
                                    <div className="spacer-20"></div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" id="submit" className="btn-main">Tamamla</button>
                    </form>
                </div>
            </div>
        </div>
    </>);
}