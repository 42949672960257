interface CheckboxProps {
    label: string;
    id?: string;
    name: string;
    checked: boolean;
    infoText?: string;
    onChange:any;
}

export default function Checkbox({label, name, id, infoText, checked = false,onChange}: CheckboxProps) {
    return (<>
        <div className="switch-with-title s2">
            <h5>{label}</h5>
            <div className="de-switch">
                <input type="checkbox" id={id} className="checkbox"
                       name={name} checked={checked} onChange={onChange}/>
                <label htmlFor="notif-item-sold"></label>
            </div>
            <div className="clearfix"></div>
            <p className="p-info">{infoText}</p>
        </div>
    </>);
}