import axios from "../utils/AxiosInstance";

export async function GetSiteSetting() {
    return axios
        .get("/siteSettings")
        .then((response): ResponseModel<SiteSetting> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<SiteSetting> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function UpdateSetting(setting: SiteSetting) {
    return axios
        .put(`/siteSettings/${setting.id}`, setting)
        .then((response): ResponseModel<SiteSetting> => {
            let setting = response.data as SiteSetting;
            console.log(response);
            return {
                data: response.data,
                status: response.status,
                message: `${setting.id} məlumatları yeniləndi.`
            };
        })
        .catch(({response}): ResponseModel<SiteSetting> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function InsertSetting(setting: SiteSetting) {
    return axios
        .post(`/siteSettings`, setting)
        .then((response): ResponseModel<SiteSetting> => {
            let setting = response.data as SiteSetting;
            return {
                data: response.data,
                status: response.status,
                message: `${setting.id} əlavə edildi.`
            };
        })
        .catch(({response}): ResponseModel<SiteSetting> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}
