import axios from "../utils/AxiosInstance";

export async function GetStatisticsInfo() {
    return axios
        .get("/dashboard")
        .then((response): ResponseModel<StatisticsInfo> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<StatisticsInfo> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}