import axios from "../utils/AxiosInstance";

export async function GetAllCarBrands() {
    return axios
        .get(`/cars/brands`,)
        .then((response): ResponseModel<string[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<string[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetAllCars(qparams?: any) {
    return axios
        .get(`/cars/`, {params: {...qparams}})
        .then((response): ResponseModel<Car[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Car[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetTopCars(count: number) {
    return axios
        .get(`/cars/top/${count}`)
        .then((response): ResponseModel<Car[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Car[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetAllCarsEvenInactive(qparams?: any) {
    return axios
        .get(`/cars/all/`, {params: {...qparams}})
        .then((response): ResponseModel<Car[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Car[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetCarById(id: string) {
    return axios
        .get(`/cars/${id}`)
        .then((response): ResponseModel<Car> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Car> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function UpdateCar(id: any, car: FormData) {
    return axios
        .put(`/cars/${id}`, car)
        .then((response): ResponseModel<Car> => {
            let car = response.data as Car;
            return {
                data: response.data,
                status: response.status,
                message: `${car.name} məlumatları yeniləndi.`
            };
        })
        .catch(({response}): ResponseModel<Car> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function InsertCar(car: FormData) {
    return axios
        .post(`/cars`, car)
        .then((response): ResponseModel<Car> => {
            let car = response.data as Car;
            return {
                data: response.data,
                status: response.status,
                message: `${car.name} əlavə edildi.`
            };
        })
        .catch(({response}): ResponseModel<Car> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function DeleteCar(id: number) {
    return axios
        .delete(`/cars/${id}`)
        .then((response): ResponseModel<Car> => {
            return {
                data: response.data,
                status: response.status,
                message: `${id} silindi.`
            };
        })
        .catch(({response}): ResponseModel<Car> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function DeleteImageById(id: any) {
    return axios
        .delete(`/cars/images/${id}`)
        .then((response): ResponseModel<Car> => {
            let car = response.data as Car;
            return {
                data: response.data,
                status: response.status,
                message: `${car.name} məlumatları yeniləndi.`
            };
        })
        .catch(({response}): ResponseModel<Car> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetGarages() {
    return axios
        .get("/garages")
        .then((response): ResponseModel<Garage[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Garage[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetGarageById(id: string) {
    return axios
        .get(`/garages/${id}`)
        .then((response): ResponseModel<Garage> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Garage> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function UpdateGarage(garage: Garage) {
    return axios
        .put(`/garages/${garage.id}`, garage)
        .then((response): ResponseModel<Garage> => {
            let garage = response.data as Garage;
            return {
                data: response.data,
                status: response.status,
                message: `${garage.name} məlumatları yeniləndi.`
            };
        })
        .catch(({response}): ResponseModel<Garage> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function InsertGarage(garage: Garage) {
    return axios
        .post(`/garages`, garage)
        .then((response): ResponseModel<Garage> => {
            let garage = response.data as Garage;
            return {
                data: response.data,
                status: response.status,
                message: `${garage.name} əlavə edildi.`
            };
        })
        .catch(({response}): ResponseModel<Garage> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function DeleteGarage(id: number) {
    return axios
        .delete(`/garages/${id}`)
        .then((response): ResponseModel<Garage> => {
            return {
                data: response.data,
                status: response.status,
                message: `${id} silindi.`
            };
        })
        .catch(({response}): ResponseModel<Garage> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetCarTypes() {
    return axios
        .get("/cartypes")
        .then((response): ResponseModel<CarType[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<CarType[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetCarTypeById(id: string) {
    return axios
        .get(`/cartypes/${id}`)
        .then((response): ResponseModel<CarType> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<CarType> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function UpdateCarType(type: CarType) {
    return axios
        .put(`/cartypes/${type.id}`, type)
        .then((response): ResponseModel<CarType> => {
            let type = response.data as CarType;
            return {
                data: response.data,
                status: response.status,
                message: `${type.name} məlumatları yeniləndi.`
            };
        })
        .catch(({response}): ResponseModel<CarType> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function InsertCarType(type: CarType) {
    return axios
        .post(`/cartypes`, type)
        .then((response): ResponseModel<CarType> => {
            let type = response.data as CarType;
            return {
                data: response.data,
                status: response.status,
                message: `${type.name} əlavə edildi.`
            };
        })
        .catch(({response}): ResponseModel<CarType> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function DeleteCarType(id: number) {
    return axios
        .delete(`/cartypes/${id}`)
        .then((response): ResponseModel<CarType> => {
            return {
                data: response.data,
                status: response.status,
                message: `${id} silindi.`
            };
        })
        .catch(({response}): ResponseModel<CarType> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}


export async function GetServices() {
    return axios
        .get("/services")
        .then((response): ResponseModel<Service[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Service[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetServiceById(id: string) {
    return axios
        .get(`/services/${id}`)
        .then((response): ResponseModel<Service> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Service> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function UpdateService(service: Service) {
    return axios
        .put(`/services/${service.id}`, service)
        .then((response): ResponseModel<Service> => {
            let garage = response.data as Garage;
            return {
                data: response.data,
                status: response.status,
                message: `${garage.name} məlumatları yeniləndi.`
            };
        })
        .catch(({response}): ResponseModel<Service> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function InsertService(service: Service) {
    return axios
        .post(`/services`, service)
        .then((response): ResponseModel<Service> => {
            let garage = response.data as Garage;
            return {
                data: response.data,
                status: response.status,
                message: `${garage.name} əlavə edildi.`
            };
        })
        .catch(({response}): ResponseModel<Service> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function DeleteService(id: number) {
    return axios
        .delete(`/services/${id}`)
        .then((response): ResponseModel<Service> => {
            return {
                data: response.data,
                status: response.status,
                message: `${id} silindi.`
            };
        })
        .catch(({response}): ResponseModel<Service> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}
