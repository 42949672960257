import axios from "../utils/AxiosInstance";

export async function GetOrders() {
    return axios
        .get("/orders")
        .then((response): ResponseModel<Order[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Order[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetTopOrders(count: number) {
    return axios
        .get("/orders/top/" + count)
        .then((response): ResponseModel<Order[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Order[]> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetOrderById(id: string) {
    return axios
        .get(`/orders/${id}`)
        .then((response): ResponseModel<Order> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<Order> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function UpdateOrder(order: Order) {
    return axios
        .put(`/garages/${order.id}`, order)
        .then((response): ResponseModel<Garage> => {
            let garage = response.data as Garage;
            return {
                data: response.data,
                status: response.status,
                message: `${garage.name} məlumatları yeniləndi.`
            };
        })
        .catch(({response}): ResponseModel<Garage> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function InsertOrder(order: Order) {
    return axios
        .post(`/orders`, order)
        .then((response): ResponseModel<Order> => {
            let garage = response.data as Garage;
            return {
                data: response.data,
                status: response.status,
                message: `${garage.name} əlavə edildi.`
            };
        })
        .catch(({response}): ResponseModel<Order> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function DeleteOrder(id: number) {
    return axios
        .delete(`/orders/${id}`)
        .then((response): ResponseModel<Order> => {
            return {
                data: response.data,
                status: response.status,
                message: `${id} silindi.`
            };
        })
        .catch(({response}): ResponseModel<Order> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}