import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../utils/Hooks";
import {carSelector, getCarById, getTypes, setCar} from "../../../redux/stores/CarReducer";
import React, {useEffect, useState} from "react";
import Input from "../../../components/Inputs/Input";
import Checkbox from "../../../components/Inputs/Checkbox";
import toast from "react-hot-toast";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import SpecificationTable from "../../../components/Admin/SpecificationsTable";
import { DeleteImageById, UpdateCar} from "../../../services/CarService";
import Logging from "../../../utils/Logging";
import ImageHolder from "../../../components/Inputs/ImageHolder";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export default function EditCar() {
    const {id} = useParams();

    const {car, types, loading, error} = useAppSelector(carSelector);
    const dispatch = useAppDispatch();

    const [state, setState] = useState<Car>(car);
    const [files, setFiles] = useState<FileList | null>();

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        formData.append("specifications", JSON.stringify(state.specifications));

        UpdateCar(id, formData)
            .then(({data, status, message}) => {
                if (status === 200) {
                    toast.success(message);
                    dispatch(setCar(data));
                } else {
                    toast.error(message);
                }
            })
            .catch(e => Logging.error(e));
    }

    const MySwal = withReactContent(Swal);

    const removeImage = (id: number) => {
        MySwal.fire({
            title: "Silmək istədiyinizdən əminsinizmi?",
            showCancelButton: true,
            cancelButtonText: "Xeyr",
            cancelButtonColor: "#f93154",
            confirmButtonColor: "#00b74a",
            confirmButtonText: "Bəli",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteImageById(id).then(({data, status, message}) => {
                    if (status === 200) {
                        toast.success(message)
                        dispatch(setCar(data));
                    } else {
                        toast.error(message);
                    }
                })
                    .catch(e => Logging.error(e));
            }
        })
    }

    useEffect(() => {
        function fetchData() {
            dispatch(getCarById(id as string));
            dispatch(getTypes())
        }

        fetchData();
    }, [dispatch, id]);
    useEffect(() => {
        setState(car);
    }, [car]);
    return (<>
        {error !== null ? toast.error(error) : loading === true ? <LoadingGearSpinner/> :
            <div className="card p-4  rounded-5">
                <div className="row">
                    <div className="col-lg-12">
                        <form id="form-create-item" className="form-border" onSubmit={handleSubmit}
                              encType='multipart/form-data'>
                            <input type='hidden' value={id} name='id'/>
                            <div className="de_tab tab_simple">

                                <ul className="de_nav">
                                    <li className="active"><span>{car.name} (#{car.id})</span></li>
                                </ul>

                                <div className="de_tab_content">
                                    <div className="tab-1">
                                        <div className="row">
                                            <div className="col-lg-6 mb20">
                                                <Input id='name' name='name' label='Adı' placeholder='Mazda CX-5'
                                                       value={state?.name} type='text'
                                                       onChange={(e: any) => setState({
                                                           ...state,
                                                           name: e.target.value
                                                       })}/>
                                            </div>
                                            <div className="col-lg-6 mb20">
                                                <Input id='manufacture' name='manufacture' label='Marka'
                                                       placeholder='Mazda'
                                                       value={state?.manufacture} type='text'
                                                       onChange={(e: any) => setState({
                                                           ...state,
                                                           manufacture: e.target.value
                                                       })}/>
                                            </div>
                                            <div className="col-lg-6 mb20">
                                                <Input id='person' name='person' label='Sərnişin sayı' placeholder='5'
                                                       value={state?.person} type='number'
                                                       onChange={(e: any) => setState({
                                                           ...state,
                                                           person: e.target.value
                                                       })}/>
                                            </div>
                                            <div className="col-lg-6 mb20">
                                                <Input id='door' name='door' label='Qapı sayı' placeholder='4'
                                                       value={state?.door} type='number'
                                                       onChange={(e: any) => setState({
                                                           ...state,
                                                           door: e.target.value
                                                       })}/>
                                            </div>
                                            <div className="col-lg-12 mb20">
                                                <h5>Tip</h5>
                                                <select name='type' id='type' className="form-control"
                                                        defaultValue={car?.carType?.id}>
                                                    {
                                                        types?.map((type: CarType) => {
                                                            return <option value={type.id}
                                                                           key={type.id}>{type.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-6 mb20">
                                                <Input id='price' name='price' label='Günlük Qiymət' placeholder='5'
                                                       value={state?.price} type='number'
                                                       onChange={(e: any) => setState({
                                                           ...state,
                                                           price: e.target.value
                                                       })}/>
                                            </div>
                                            <div className="col-lg-6 mb20">
                                                <Input id='pricePerHour' name='pricePerHour' label='Həftəlik Qiymət' placeholder='5'
                                                       value={state?.pricePerHour} type='number'
                                                       onChange={(e: any) => setState({
                                                           ...state,
                                                           pricePerHour: e.target.value
                                                       })}/>
                                            </div>
                                            <div className="col-lg-12 mb20">
                                                <Input id='about' name='about' label='Haqqında' placeholder='5'
                                                       value={state?.about} type='text'
                                                       onChange={(e: any) => setState({
                                                           ...state,
                                                           about: e.target.value
                                                       })}/>
                                            </div>
                                            <div className="col-lg-12 mb20">
                                                <h5>Xüsusiyyətlər</h5>
                                                <SpecificationTable specifications={state?.specifications}
                                                                    onChange={(e) => setState({
                                                                        ...state,
                                                                        specifications: e
                                                                    })}/>
                                            </div>
                                            <div className="col-lg-12 mb20">
                                                <h5>Şəkillər</h5>
                                                <div style={{
                                                    backgroundSize: "100%",
                                                    backgroundRepeat: "no-repeat",
                                                    marginBottom: 30
                                                }}
                                                >
                                                    <div style={{display: "flex", gap: 5}}>
                                                        {
                                                            state?.images?.map((image) => {
                                                                return <ImageHolder image={image} key={image.id}
                                                                                    onRemove={() => removeImage(image.id)}/>
                                                            })
                                                        }</div>
                                                </div>
                                                <br/>
                                                <input type='file' multiple={true} name={'image'} accept="image/png, image/jpeg, image/webp"
                                                       onChange={(e) => setFiles(e?.currentTarget?.files)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 mb-sm-20">
                                            <Checkbox
                                                label='Saytda görünsün'
                                                id="notif-item-sold"
                                                name='isActive'
                                                checked={state?.isActive}
                                                onChange={(e: any) => setState({...state, isActive: e.target.checked})}
                                                infoText='Bu avtomobilin saydan görünüb görünməməsini təmin edir.'/>
                                            <div className="spacer-20"></div>
                                        </div>
                                        <div className="spacer-20"></div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" id="submit" className="btn-main">Tamamla</button>
                        </form>
                    </div>
                </div>
            </div>
        }

    </>);
}