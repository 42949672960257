import CarListItem from "../../../components/Main/Car/CarListItem";
import { useAppDispatch, useAppSelector } from "../../../utils/Hooks";
import { carSelector, getAllCars } from "../../../redux/stores/CarReducer";
import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../../components/LoadingGearSpinner";
import SideFilterBar from "../../../components/Main/Filters/SideFilterBar";
import { Helmet } from "react-helmet";
import { settingSelector } from "../../../redux/stores/SiteReducer";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "@mantine/core";
import { useTranslation } from "react-i18next";

export default function CarsList() {
  const { t } = useTranslation();
  const { cars, loading, error } = useAppSelector(carSelector);
  const dispatch = useAppDispatch();
  const [params, setParams] = useSearchParams();

  const { setting } = useAppSelector(settingSelector);
  const carSectionTop = useRef<null | HTMLDivElement>(null);

  //#region Pagination
  const PAGE_SIZE = 10;
  const initPage =
    Number(params.get("page")) === 0 ? 1 : Number(params.get("page"));
  const [page, setPage] = useState(initPage ?? 1);
  const [records, setRecords] = useState(cars.slice(0, PAGE_SIZE));
  const pageCount = Math.ceil(cars.length / PAGE_SIZE);

  const changePage = (pageNo: number) => {
    setParams(`page=${pageNo}`);
    setPage(pageNo);
    carSectionTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    function change() {
      const from = (page - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      setRecords(cars.slice(from, to));
      carSectionTop.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    change();
  }, [page, initPage]);
  //#endregion

  useEffect(() => {
    function fetchData() {
      dispatch(getAllCars(null));
    }

    fetchData();
  }, []);

  useEffect(() => {
    setRecords(cars.slice(0, PAGE_SIZE));
  }, [cars]);
  return (
    <>
      <Helmet>
        <title>RegesRent.Az | {t("car_list_page.title")}</title>
        <meta name='keywords' content={setting?.keywords} />
      </Helmet>
      <div className='no-bottom no-top' id='content'>
        <div id='top' ref={carSectionTop}></div>

        <section id='subheader' className='jarallax text-light'>
          <div className='center-y relative text-center'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12 text-center'>
                  <h1 className='text-black'>{t("car_list_page.title")}</h1>
                </div>
                <div className='clearfix'></div>
              </div>
            </div>
          </div>
        </section>

        <section id='section-cars'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-3'>
                <SideFilterBar />
              </div>

              <div className='col-lg-9'>
                <div className='row'>
                  {error !== null ? (
                    error
                  ) : loading === true ? (
                    <LoadingSpinner />
                  ) : (
                    records?.map((car: Car) => (
                      <div className='col-xl-6 col-lg-8' key={car.id}>
                        <CarListItem {...car} />
                      </div>
                    ))
                  )}
                </div>
                <div className='row'>
                  <div className=''>
                    <Pagination
                      value={page}
                      color='green'
                      onChange={changePage}
                      total={pageCount}
                      className='justify-content-center'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <a href='#' id='back-to-top'></a>
    </>
  );
}
