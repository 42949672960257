import {Helmet} from "react-helmet";
import CarList from "../../../components/Main/Car/CarList";
import Hero from "../../../components/Main/Hero";
import {useAppSelector} from "../../../utils/Hooks";
import {settingSelector} from "../../../redux/stores/SiteReducer";
import Features from "../../../components/Main/Features";
import { useTranslation } from "react-i18next";
export default function Main() {
    const { t } = useTranslation();
    const {setting} = useAppSelector(settingSelector);
    return (
        <>
            <Helmet>
                <title>RegesRent.Az - {t("home_page.title")}</title>
                <meta name="description" content={setting?.about}/>
                <meta name="keywords" content={setting?.keywords}/>
            </Helmet>
            <div className='no-bottom no-top' id='content'>
                <div id='top'></div>
                <Hero/>
                <CarList/>
                <Features/>
            </div>
        </>
    );
}
