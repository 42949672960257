import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {GetServices} from "../../services/CarService";

interface InitState {
    loading: boolean;
    error: any;
    services: Array<Service>;
}

const initialState: InitState = {
    loading: false,
    error: null,
    services: [] as Service[],
};

//methods
export const getServices = createAsyncThunk("getServices", async () => {
    try {
        return await GetServices();
    } catch (ex: any) {
        return ex.response.data;
    }
});

export const serviceSlice = createSlice({
    name: "serviceSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //GetGarages
        builder.addCase(getServices.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getServices.fulfilled, (state, action) => {
            state.services = action.payload.data;
            state.loading = false;
        });
        builder.addCase(getServices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

// export const {} = garageSlice.actions;
export const serviceSelector = (state: RootState) => state.serviceReducer;
export default serviceSlice.reducer;
