import {useAppDispatch, useAppSelector} from "../../../utils/Hooks";
import toast from "react-hot-toast";
import React, {FormEvent, useEffect, useState} from "react";
import {getSettings, settingSelector} from "../../../redux/stores/SiteReducer";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import Input from "../../../components/Inputs/Input";
import SocialLinkTable from "../../../components/Admin/SocialLinkTable";
import {UpdateSetting} from "../../../services/SiteService";
import Logging from "../../../utils/Logging";

export default function SiteSettings() {
    const {setting, loading, error} = useAppSelector(settingSelector);
    const [state, setState] = useState<SiteSetting>(setting);

    const dispatch = useAppDispatch();
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        UpdateSetting(state).then((res) => {
            if (res.status === 200) {
                toast.success(res.message)
                dispatch(getSettings());
            } else {
                toast.error(res.message);
            }
        })
            .catch(e => Logging.error(e));
    }

    useEffect(() => {
        setState(setting);
    }, [setting]);
    return (<>
        {error !== null ? toast.error(error) : loading === true ? <LoadingGearSpinner/> :
            <div className="card p-4  rounded-5">
                <h4>Ayarlar</h4>
                <div className="row">
                    <div className="col-lg-12">
                        <form id="form-create-item" className="form-border" onSubmit={handleSubmit}>
                            <div className="card p-4  rounded-5">
                                <h4>Əlaqə məlumatları</h4>
                                <div className="row">
                                    <div className="col-lg-6 mb20">
                                        <Input id='about' name='about' label='Telefon'
                                               value={state?.contact?.phone} type='text'
                                               onChange={(e: any) => setState({
                                                   ...state,
                                                   contact: {...state.contact, phone: e.target.value}
                                               })}/>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <Input id='about' name='about' label='E-mail'
                                               value={state?.contact?.email} type='text'
                                               onChange={(e: any) => setState({
                                                   ...state,
                                                   contact: {...state.contact, email: e.target.value}
                                               })}/>
                                    </div>
                                    <div className="col-lg-12 mb20">
                                        <Input id='officeAddress' name='officeAddress' label='Ünvan'
                                               value={state?.contact?.officeAddress} type='text'
                                               onChange={(e: any) => setState({
                                                   ...state,
                                                   contact: {...state.contact, officeAddress: e.target.value}
                                               })}/>
                                    </div>
                                </div>
                            </div>
                            <div className="card p-4  rounded-5">
                                <h4>Sosial linklər</h4>
                                <div className="row">
                                    <SocialLinkTable socialLinks={state?.socialLinks} onChange={(e) => setState({
                                        ...state,
                                        socialLinks: e
                                    })}/>
                                </div>
                            </div>
                            <div className="card p-4  rounded-5">
                                <h4>Digər</h4>
                                <div className="row">
                                    <div className="col-lg-6 mb20">
                                        <Input name='workDaysString' label='İş vaxtı'
                                               value={state?.workDaysString} type='text'
                                               onChange={(e: any) => setState({
                                                   ...state,
                                                   workDaysString: e.target.value
                                               })}/>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <Input name='termsMdFileUrl' label='İcarə Şərtləri (Markdown (.md) fayl ünvanı)'
                                               value={state?.termsMdFileUrl} type='text'
                                               onChange={(e: any) => setState({
                                                   ...state,
                                                   termsMdFileUrl: e.target.value
                                               })}/>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <Input name='catalogUrl' label='Katalog linki'
                                               value={state?.catalogUrl} type='text'
                                               onChange={(e: any) => setState({
                                                   ...state,
                                                   catalogUrl: e.target.value
                                               })}/>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <Input name='logoLight' label='Logo (light mode)'
                                               value={state?.logoLight} type='text'
                                               onChange={(e: any) => setState({
                                                   ...state,
                                                   logoLight: e.target.value
                                               })}/>
                                    </div>
                                    <div className="col-lg-6 mb20">
                                        <Input name='logoDark' label='Logo (dark mode)'
                                               value={state?.logoDark} type='text'
                                               onChange={(e: any) => setState({
                                                   ...state,
                                                   logoDark: e.target.value
                                               })}/>
                                    </div>
                                    <div className="col-lg-12 mb20">
                                        <h5>Haqqında</h5>
                                        <textarea
                                            className='form-control'
                                            rows={5}
                                            cols={20}
                                            onChange={(e: any) => setState({
                                                ...state,
                                                about: e.target.value
                                            })}
                                            value={state?.about}/>
                                    </div>
                                    <div className="col-lg-12 mb20">
                                        <h5>SEO üçün açar sözlər (Keywords) (vergüllə yazın)</h5>
                                        <textarea
                                            className='form-control'
                                            rows={5}
                                            cols={20}
                                            onChange={(e: any) => setState({
                                                ...state,
                                                keywords: e.target.value
                                            })}
                                            value={state?.keywords}/>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" id="submit" className="btn-main">Tamamla</button>
                        </form>
                    </div>
                </div>
            </div>
        }
    </>);
}