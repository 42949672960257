import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "./utils/Hooks";
import {getSettings, settingSelector} from "./redux/stores/SiteReducer";
import {Toaster} from 'react-hot-toast';
import {Route, Routes} from "react-router-dom";
import Main from "./pages/Main/Home";
import MainLayout from "./components/Layout/Main/MainLayout";
import CarDetail from "./pages/Main/Detail";
import AdminLayout from "./components/Layout/Admin/AdminLayout";
import CarsList from "./pages/Main/CarList";
import Dashboard from "./pages/Admin/Dashboard";
import Error404 from "./pages/Error/404";
import CarsTable from "./pages/Admin/Cars";
import EditCar from "./pages/Admin/Cars/editcar";
import AddCar from "./pages/Admin/Cars/addcar";
import SiteSettings from "./pages/Admin/SiteSettings";
import CarTypesTable from "./pages/Admin/CarTypes";
import EditCarType from "./pages/Admin/CarTypes/edit-type";
import AddCarType from "./pages/Admin/CarTypes/add-type";
import GaragesTable from "./pages/Admin/Garages";
import EditGarage from "./pages/Admin/Garages/edit-garage";
import AddGarage from "./pages/Admin/Garages/add-garage";
import Login from "./pages/Admin/Auth/Login";
import RequireUser from "./components/RequireUser";
import Preloader from "./components/Preloader";
import About from "./pages/Main/About";
import Agreement from "./pages/Main/Agreement";
import Services from "./pages/Main/Services";
import Contact from "./pages/Main/Contact";
import Social from "./pages/Main/Social";
import ServicesTable from "./pages/Admin/Services";

function App() {

    const dispatch = useAppDispatch();

    const {preloader} = useAppSelector(settingSelector);

    useEffect(() => {
        function fetchData() {
            dispatch(getSettings());
        }

        fetchData();
    }, []);
    return (
        preloader ? <Preloader/> : <>
            <div id='wrapper'>
                <Toaster/>
                <Routes>
                    <Route element={<MainLayout/>}>
                        <Route path='/' element={<Main/>}/>
                        <Route path='/car/:id' element={<CarDetail/>}/>
                        <Route path='/cars/' element={<CarsList/>}/>
                        <Route path='/about/' element={<About/>}/>
                        <Route path='/services/' element={<Services/>}/>
                        <Route path='/contact/' element={<Contact/>}/>
                        <Route path='/social/' element={<Social/>}/>
                        <Route path='/agreement/' element={<Agreement/>}/>
                        <Route path='/login' element={<Login/>}/>
                    </Route>
                    <Route element={<RequireUser allowedRoles={['admin']}/>}>
                        <Route element={<AdminLayout/>}>
                            <Route path='/admin' element={<Dashboard/>}/>
                            <Route path='/admin/dashboard' element={<Dashboard/>}/>
                            <Route path='/admin/setting' element={<SiteSettings/>}/>
                            <Route path='/admin/types' element={<CarTypesTable/>}/>
                            <Route path='/admin/edit-type/:id' element={<EditCarType/>}/>
                            <Route path='/admin/add-type/' element={<AddCarType/>}/>
                            <Route path='/admin/services' element={<ServicesTable/>}/>
                            <Route path='/admin/garages' element={<GaragesTable/>}/>
                            <Route path='/admin/edit-garage/:id' element={<EditGarage/>}/>
                            <Route path='/admin/add-garage/' element={<AddGarage/>}/>
                            <Route path='/admin/cars' element={<CarsTable/>}/>
                            <Route path='/admin/edit-car/:id' element={<EditCar/>}/>
                            <Route path='/admin/add-car' element={<AddCar/>}/>
                        </Route>
                    </Route>
                    <Route path='*' element={<Error404/>}/>
                </Routes>
            </div>
        </>
    );
}

export default App;
