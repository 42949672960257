import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../utils/Hooks";
import {carSelector, getAllCarsEvenInactive,} from "../../../redux/stores/CarReducer";
import {Link} from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {DeleteCar} from "../../../services/CarService";
import toast from "react-hot-toast";
import Logging from "../../../utils/Logging";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";

export default function CarsTable() {
    const {cars, loading, error} = useAppSelector(carSelector);
    const dispatch = useAppDispatch();

    const MySwal = withReactContent(Swal);

    const deleteHandler = (id: number) => {
        MySwal.fire({
            title: "Silmək istədiyinizdən əminsinizmi?",
            showCancelButton: true,
            cancelButtonText: "Xeyr",
            cancelButtonColor: "#f93154",
            confirmButtonColor: "#00b74a",
            confirmButtonText: "Bəli",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteCar(id).then((res) => {
                    if (res.status === 204) {
                        toast.success(res.message)
                        dispatch(getAllCarsEvenInactive(null));
                    } else {
                        toast.error(res.message);
                    }
                })
                    .catch(e => Logging.error(e));
            }
        })
    }

    useEffect(() => {
        function fetchData() {
            dispatch(getAllCarsEvenInactive(null));
        }

        fetchData();
    }, []);
    return (
        <>
            <div className="card p-4 rounded-5 mb25">
                <h4>Avtomobillər</h4>
                {
                    error !== null ? error :
                        loading === true ? <LoadingGearSpinner/> :
                            <table className="table table-hover de-table">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">ID</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Adı</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Tip</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Qiymət</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Avtiv/Passiv</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Əməliyyatlar</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    cars?.map((car) => {
                                        return (<tr key={car?.id}>
                                            <td>
                                                <span className="d-lg-none d-sm-block">ID</span>
                                                <div className="badge bg-gray-100 text-dark">#{car?.id}</div>
                                            </td>
                                            <td>
                                                <span className="d-lg-none d-sm-block">Adı</span>
                                                <span className="bold">{car?.name}</span>
                                            </td>
                                            <td>
                                                <span className="d-lg-none d-sm-block">Tip</span>
                                                {car?.carType?.name}
                                            </td>
                                            <td>
                                                <span className="d-lg-none d-sm-block">Qiymət</span>
                                                {car?.price}
                                            </td>
                                            <td>
                                                <span className="d-lg-none d-sm-block">Aktiv/Passiv</span>
                                                {car?.isActive ?
                                                    <span className="badge rounded-pill bg-success">Aktiv</span> :
                                                    <span className="badge rounded-pill bg-danger">Passiv</span>}
                                            </td>
                                            <td>
                                                <Link to={'/admin/edit-car/' + car?.id}
                                                      className="btn btn-sm badge rounded-pill bg-success">Düzəlt</Link>
                                                <button className="btn btn-sm badge rounded-pill bg-danger ml10"
                                                        onClick={(e) => deleteHandler(car?.id)}>Sil
                                                </button>
                                            </td>
                                        </tr>)
                                    })
                                }
                                </tbody>
                            </table>
                }
            </div>
        </>
    );
}