import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../utils/Hooks";
import {authSelector, setUser} from "../redux/stores/AuthReducer";
import {GetUserById} from "../services/AuthService";
import Logging from "../utils/Logging";
import {useEffect} from "react";

const RequireUser = ({allowedRoles}: { allowedRoles: string[] }) => {
    const {user} = useAppSelector(authSelector);
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        function getData() {
            GetUserById().then(({data, status, message}) => {
                if (status === 200) {
                    dispatch(setUser(data))
                } else {
                    dispatch(setUser(null))
                    Logging.error(message);
                }
            }).catch((e) => {
                dispatch(setUser(null));
                Logging.error(e);
            })
        }

        getData();
    }, []);

    return user &&
    allowedRoles.includes(user?.role as string) ? (
        <Outlet/>
    ) : (
        <Navigate to='/login' state={{from: location}} replace/>
    );
};

export default RequireUser;

