import { useEffect } from "react";
import CarListItem from "./CarListItem";
import { useMediaQuery } from "@mantine/hooks";
import { useMantineTheme, rem, Grid } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../utils/Hooks";
import { carSelector, getTopCars } from "../../../redux/stores/CarReducer";
import LoadingGearSpinner from "../../LoadingGearSpinner";
import { useTranslation } from "react-i18next";

export default function CarList() {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const { topCars, loading, error } = useAppSelector(carSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    function fetchData() {
      dispatch(getTopCars());
    }

    fetchData();
  }, []);
  return (
    <section id='section-cars' className='no-top'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6 offset-lg-3 text-center'>
            <h2>{t("carlist.we_have")}</h2>
            <div className='spacer-20'></div>
          </div>
          <div className='clearfix'></div>
          {loading ? (
            <LoadingGearSpinner />
          ) : (
            <Grid>
              {topCars?.map((item: Car) => (
                <Grid.Col span={mobile ? 12 : 4} key={item.id}>
                  <CarListItem {...item} />
                </Grid.Col>
              ))}
            </Grid>
          )}
        </div>
      </div>
    </section>
  );
}
