import React from 'react';
import {Outlet} from 'react-router-dom';
import Header from "../Main/Header";
import Footer from "../Main/Footer";
import Sidebar from "../../Admin/Sidebar";


const AdminLayout = () => {
    return (
        <>
            <Header/>
            <div className="no-bottom no-top" id="content">
                <div id="top"></div>

                <section id='subheader' className='text-dark' style={{marginTop:"50px"}}>
                    <div className='center-y relative text-center'>
                        <div className='container'>
                        </div>
                    </div>
                </section>

                <section id="section-cars" className="bg-gray-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb30">
                                <Sidebar/>
                            </div>

                            <div className="col-lg-9">
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer/>
        </>
    )
}

export default AdminLayout;