import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import { useAppSelector } from "../../../utils/Hooks";
import { settingSelector } from "../../../redux/stores/SiteReducer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Social() {
  const { t } = useTranslation();
  const { setting, loading, error } = useAppSelector(settingSelector);

  return (
    <>
      <Helmet>
        <title>RegesRent.Az {t("social_page.title")}</title>
        <meta name='description' content={setting?.about} />
        <meta name='keywords' content={setting?.keywords} />
      </Helmet>
      {error === null ? (
        loading === true ? (
          <LoadingGearSpinner />
        ) : (
          <div className='no-bottom no-top' id='content'>
            <div id='top'></div>
            <section id='subheader' className='jarallax text-light'>
              {/*<img src="/images/background/subheader.webp" className="jarallax-img" alt=""/>*/}
              <div className='center-y relative text-center'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 text-center '>
                      <h1 className='text-black'>{t("social_page.title")}</h1>
                    </div>
                    <div className='clearfix'></div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className='container'>
                <div className='row g-5'>
                  <div className='col-lg-12'>
                    <div className='col-lg-12'>
                      <div
                        className='de-box mb30'
                        style={{
                          backgroundSize: "100%",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <address className='s1'>
                          {setting?.socialLinks.map((link) => (
                            <span key={link.id}>
                              <i className={`id-color ${link.icon} fa-lg`}></i>
                              <a
                                href={link.link}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {link.name}
                              </a>
                            </span>
                          ))}
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='spacer-double'></div>
              </div>
            </section>
          </div>
        )
      ) : (
        error
      )}
    </>
  );
}
