import LanguageSelector from "../Inputs/LanguageSelector";

interface TopBarParams {
  contact: Contact;
  socialLinks: SocialLink[];
  workDaysString: string;
}

export default function TopBar({
  contact,
  socialLinks,
  workDaysString,
}: TopBarParams) {
  return (
    <div id='topbar' className='topbar-dark text-light'>
      <div className='container'>
        <div className='topbar-left xs-hide'>
          <div className='topbar-widget'>
            <div className='topbar-widget'>
              <a href='/#'>
                <i className='fa fa-phone'></i>
                {contact?.phone}
              </a>
            </div>
            <div className='topbar-widget'>
              <a href='/#'>
                <i className='fa fa-envelope'></i>
                {contact?.email}
              </a>
            </div>
            <div className='topbar-widget'>
              <a href='/#'>
                <i className='fa fa-clock-o'></i>
                {workDaysString}
              </a>
            </div>
          </div>
        </div>

        <div className='topbar-right'>
          <div className='social-icons'>
            {socialLinks?.map((item) => (
              <a href={item.link} key={item.id} target='_blank'>
                <i className={item.icon + " fa-lg"}></i>
              </a>
            ))}
          </div>
          <div className='social-icons'>
            <LanguageSelector />
          </div>
        </div>
        <div className='clearfix'></div>
      </div>
    </div>
  );
}
