import {useState} from "react";
import {useAppDispatch} from "../../../utils/Hooks";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import {Signin} from "../../../services/AuthService";
import {setUser} from "../../../redux/stores/AuthReducer";
import toast from "react-hot-toast";
import {useNavigate} from 'react-router-dom';

export default function Login() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(e.target);
        Signin(formData).then(({data, status}) => {
            if (status === 401 || status === 400) {
                toast.error("Login və ya şifrə yanlışdır.")
            } else {
                toast.success(`${data?.username} uğurla giriş etdi.`);
                dispatch(setUser(data));
                navigate("/admin");
            }
        }).catch((e) => {
            console.log(e);
        }).finally(
            () => {
                setLoading(false);
            }
        );
    }

    return (<>
            <div className="no-bottom no-top" id="content">
                <div id="top"></div>
                <section id="section-hero" aria-label="section" className="jarallax"
                         style={{backgroundColor: "#ECF0F1"}}>
                    <div className="v-center">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-4 offset-lg-4">
                                    <div className="padding40 rounded-3 shadow-soft" data-bgcolor="#ffffff"
                                         style={{backgroundColor: "white"}}>
                                        <h4>Login</h4>
                                        <div className="spacer-10"></div>
                                        <form id="form_register" className="form-border" method="post"
                                              onSubmit={handleSubmit}>
                                            <div className="field-set">
                                                <input type="text" name="username" id="username"
                                                       className="form-control"
                                                       placeholder="Username" value={username}
                                                       onChange={(e) => setUsername(e.target.value)}/>
                                            </div>
                                            <div className="field-set">
                                                <input type="password" name="password" id="password"
                                                       className="form-control"
                                                       value={password}
                                                       onChange={(e) => setPassword(e.target.value)}/>
                                            </div>
                                            <div id="submit">
                                                {
                                                    loading
                                                        ? <LoadingGearSpinner/>
                                                        :
                                                        <input type="submit" id="send_message" value="Sign In"
                                                               className="btn-main btn-fullwidth rounded-3"/>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}