import carReducer from "./stores/CarReducer";
import settingReducer from "./stores/SiteReducer";
import garageReducer from "./stores/GarageReducer";
import serviceReducer from "./stores/ServiceReducer";
import authReducer from "./stores/AuthReducer";
import {configureStore} from "@reduxjs/toolkit";

export const store = configureStore({
    reducer: {
        carReducer,
        settingReducer,
        garageReducer,
        authReducer,
        serviceReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false,}),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
