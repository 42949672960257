import React, {useEffect, useState} from "react";
import InfoCards from "../../../components/Admin/InfoCards";
import {GetTopOrders} from "../../../services/OrderService";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";

export default function Dashboard() {
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchData = () => {
        setLoading(true);
        GetTopOrders(5).then(({data}) => {
            setOrders(data);
        }).catch((e) => {
            setError(e.message);
        }).finally(() => {
            setLoading(false);
        });
    }
    const showOrderState = (orderState: any) => {
        switch (orderState) {
            case 1:
                return <span className="badge bg-warning">Gözləmədə</span>;
            case 2:
                return <span className="badge bg-success">Təsdiqləndi</span>;
            case 3:
                return <span className="badge bg-danger">Ləğv edildi</span>;
            case 4:
                return <span className="badge bg-info">Tamamlandı</span>;
            default:
                return "Naməlum";
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <InfoCards/>

            <div className="card p-4 rounded-5 mb25">
                <h4>Son sifarişlər</h4>
                {
                    loading ? <LoadingGearSpinner/> : <table className="table table-hover de-table">
                        <thead>
                        <tr>
                            <th scope="col">
                                <span className="text-uppercase fs-12 text-gray">ID</span>
                            </th>
                            <th scope="col">
                                <span className="text-uppercase fs-12 text-gray">Müştəri Adı</span>
                            </th>
                            <th scope="col">
                                <span className="text-uppercase fs-12 text-gray">Müştəri Nömrəsi</span>
                            </th>
                            <th scope="col">
                                <span className="text-uppercase fs-12 text-gray">Avtomobil</span>
                            </th>
                            <th scope="col">
                                <span className="text-uppercase fs-12 text-gray">Gün Sayı</span>
                            </th>
                            <th scope="col">
                                <span className="text-uppercase fs-12 text-gray">Qiymət</span>
                            </th>
                            <th scope="col">
                                <span className="text-uppercase fs-12 text-gray">Status</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            orders?.map((order) => {
                                return (<tr key={order?.id}>
                                    <td>
                                        <span className="d-lg-none d-sm-block">ID</span>
                                        <div className="badge bg-gray-100 text-dark">#{order?.id}</div>
                                    </td>
                                    <td>
                                        <span className="d-lg-none d-sm-block">Müştəri Adı</span>
                                        <span className="bold">{order?.customerName}</span>
                                    </td>
                                    <td>
                                        <span className="d-lg-none d-sm-block">Müştəri Nömrəsi</span>
                                        {order?.customerPhone}
                                    </td>
                                    <td>
                                        <span className="d-lg-none d-sm-block">Avtomobil</span>
                                        {order?.car?.name}
                                    </td>
                                    <td>
                                        <span className="d-lg-none d-sm-block">Gün Sayı</span>
                                        {order.dayCount}
                                    </td>
                                    <td>
                                        <span className="d-lg-none d-sm-block">Qiymət</span>
                                        {order?.dayCount * order?.car?.price}
                                    </td>
                                    <td>
                                        <span className="d-lg-none d-sm-block">Status</span>
                                        {
                                            showOrderState(order?.orderState)
                                        }

                                    </td>
                                </tr>)
                            })
                        }
                        </tbody>
                    </table>
                }
            </div>
        </>
    );
}
