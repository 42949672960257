import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../utils/Hooks";
import {authSelector, logout} from "../../redux/stores/AuthReducer";
import {Signout} from "../../services/AuthService";
import toast from "react-hot-toast";

export default function Sidebar() {
    const {user} = useAppSelector(authSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const logoutHandle = () => {
        Signout()
            .then(({ status}) => {
                if (status === 200) {
                    toast.success("Uğurla çıxış etdiniz.");
                    navigate("/");
                }
            })
            .catch((e) => {
                toast.success(e.message);
            })
        dispatch(logout)
    }
    return (
        <>
            <div className="card p-4 rounded-5">
                <div className="profile_avatar">
                    <div className="profile_img">
                    </div>
                    <div className="profile_name">
                        <h4>
                            {user?.username}
                            <span className="profile_username text-gray">{user?.email}</span>
                        </h4>
                    </div>
                </div>
                <div className="spacer-20"></div>
                <ul className="menu-col">
                    <li>
                        <NavLink to="/admin/dashboard"><i className="fa fa-home"></i>Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/setting"><i className="fa fa-gear"></i>Sayt ayarları</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/types"><i className="fa fa-list"></i>Avtomobil növləri</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/services"><i className="fa fa-indent"></i>Xidmətlər</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/garages"><i className="fa fa-address-card"></i>Ünvanlar</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/cars"><i className="fa fa-car"></i>Maşınlar</NavLink>
                    </li>
                    <li>
                        <NavLink to="/admin/add-car"><i className="fa fa-car"></i>Maşın əlavə et</NavLink>
                    </li>
                    <li className='text-center mt-1'>
                        <button className='btn btn-danger' onClick={logoutHandle}><i className="fa fa-sign-out"></i>Sign
                            Out
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
}