import Input from "../Inputs/Input";
import React, {useEffect, useState} from "react";
import {Checkbox} from "@mantine/core";

interface SocialLinkTableProps {
    socialLinks: SocialLink[];
    onChange: (socialLinks: SocialLink[]) => void;
}

export default function SocialLinkTable({socialLinks, onChange}: SocialLinkTableProps) {
    const [name, setName] = useState<string>("");
    const [icon, setIcon] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [id, setId] = useState<number>(0);
    const [isActive, setIsActive] = useState<boolean>(false);
    const handleSpecTable = (e: any, i: number) => {
        let clone = [...socialLinks];
        let id = clone[i].id;
        let name = clone[i].name;
        let icon = clone[i].icon;
        let link = clone[i].link;
        let active = clone[i].isActive;

        if (e.target.name === 'name') {
            name = e.target.value;
        }
        switch (e.target.name) {
            case 'name':
                name = e.target.value;
                break;
            case 'icon':
                icon = e.target.value;
                break;
            case 'link':
                link = e.target.value;
                break;
            case 'isActive':
                active = e.target.checked;
                break;
        }

        clone[i] = {id, name, icon, link, isActive: active};
        onChange([...clone]);
    };
    const deleteRowHandle = (e: any, id: number) => {
        e.preventDefault();
        const newSpecs = socialLinks.filter(link => link.id !== id);
        onChange([...newSpecs]);
    }
    const addHandler = (e: any) => {
        e.preventDefault();
        onChange([...socialLinks, {id, name, icon, link: url, isActive}]);
        setName("");
        setIcon("");
        setUrl("");
        setIsActive(false);
    }

    useEffect(() => {
        const linkIds = socialLinks?.map(item => item.id);
        const lastId = (linkIds === undefined || linkIds === null || linkIds?.length === 0) ? 0 : Math.max(...linkIds);
        setId(lastId + 1);
    }, [socialLinks]);
    return (<>
        <div className="card p-4 rounded-5 mb25">
            <table className="table table-sm">
                <thead>
                <tr>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray">ID</span>
                    </th>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray">Adı</span>
                    </th>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray">Icon</span>
                    </th>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray">Link</span>
                    </th>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray">Aktiv/Passiv</span>
                    </th>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray"></span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    socialLinks?.map((link: SocialLink, index) => (
                        <tr key={link.id}>
                            <td>
                                <span className="d-lg-none d-sm-block">ID</span>
                                <div className="badge bg-gray-100 text-dark mt-4">#{link.id}</div>
                            </td>
                            <td>
                                <span className="d-lg-none d-sm-block">Adı</span>
                                <Input name='name' label=''
                                       value={link.name} type='text'
                                       onChange={(e: any) => handleSpecTable(e, index)}/>
                            </td>
                            <td>
                                <span className="d-lg-none d-sm-block">Icon</span>
                                <Input name='icon' label=''
                                       value={link.icon} type='text'
                                       onChange={(e: any) => handleSpecTable(e, index)}/>
                            </td>
                            <td>
                                <span className="d-lg-none d-sm-block">Link</span>
                                <Input name='link' label=''
                                       value={link.link} type='text'
                                       onChange={(e: any) => handleSpecTable(e, index)}/>
                            </td>
                            <td>
                                <span className="d-lg-none d-sm-block">Aktiv/Passiv</span>
                                <Checkbox className='bg-gray-100 rounded-1 text-dark mt-4'
                                          label={link.isActive ? "Gizlət" : "Göstər"}
                                          name='isActive' checked={link.isActive}
                                          onChange={(e: any) => handleSpecTable(e, index)}
                                />
                            </td>
                            <td>
                                <span className="d-lg-none d-sm-block"></span>
                                <button
                                    onClick={(e) => deleteRowHandle(e, link.id)}
                                    className="btn btn-sm badge rounded-pill bg-danger mt-3"><i
                                    className='fa fa-trash'></i>
                                </button>
                            </td>
                        </tr>
                    ))
                }
                <tr>
                    <td>
                        <span className="d-lg-none d-sm-block">ID</span>
                        <div className="badge bg-gray-100 text-dark">#{id}</div>
                    </td>
                    <td>
                        <span className="d-lg-none d-sm-block">Adı</span>
                        <Input name='name' label=''
                               value={name} type='text'
                               onChange={(e: any) => setName(e.target.value)}/>
                    </td>
                    <td>
                        <span className="d-lg-none d-sm-block">Icon</span>
                        <Input name='icon' label=''
                               value={icon} type='text'
                               onChange={(e: any) => setIcon(e.target.value)}/>
                    </td>
                    <td>
                        <span className="d-lg-none d-sm-block">Link</span>
                        <Input name='link' label=''
                               value={url} type='text'
                               onChange={(e: any) => setUrl(e.target.value)}/>
                    </td>
                    <td>
                        <span className="d-lg-none d-sm-block">Aktiv/Passiv</span>
                        <input name='isActive'
                               checked={isActive} type='checkbox'
                               onChange={(e: any) => setIsActive(e.target.value)}/>
                    </td>
                    <td>
                        <span className="d-lg-none d-sm-block"></span>
                        <button
                            onClick={addHandler}
                            className="btn btn-sm badge rounded-pill bg-success mt-3">
                            <i className='fa fa-plus'></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </>)
}