import {useState} from "react";
import Zoom from 'react-medium-image-zoom';
import {set} from "lodash";

type Props = {
    images: string[];
};

export default function ZoomableSlider({images}: Props) {
    const [index, setIndex] = useState<number>(0);
    const [hover, setHover] = useState<boolean>(false);

    const previus = () => {
        if (index <= 0) {
            setIndex(images.length - 1);
        } else {
            setIndex(index - 1);
        }
    }
    const next = () => {
        if (index >= images.length - 1) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    }
    const navHover = () => {
        setHover(true);
    }
    const navNoHover = () => {
        setHover(false);
    }
    return (
        <div className="row centered" style={{justifyContent: "center"}}>
            <div
                id='slider-carousel'
                className='owl-carousel owl-loaded owl-drag'
                style={{backgroundSize: "100%", backgroundRepeat: "no-repeat", width: "80%"}}
            >
                <div
                    className='owl-stage-outer'
                    style={{backgroundSize: "100%", backgroundRepeat: "no-repeat"}}
                >
                    <div className='owl-stage'>
                        <div className='owl-item'>
                            <div
                                className='item'
                                style={{
                                    backgroundSize: "100%",
                                    backgroundRepeat: "no-repeat",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <i className="fa fa-arrow-circle-left fa-3x" style={{
                                    display: "",
                                    marginRight: "-15%",
                                    zIndex: 999,
                                    color: "whitesmoke",
                                    opacity: hover ? "80%" : "40%"
                                }}
                                   onMouseEnter={navHover}
                                   onMouseLeave={navNoHover}
                                   onClick={previus}
                                ></i>
                                <Zoom>
                                    <img src={process.env.REACT_APP_API_URL + images[index]} alt='ssd' loading="lazy"
                                         style={{borderRadius: "5px"}}/>
                                </Zoom>
                                <i className="fa fa-arrow-circle-right fa-3x" style={{
                                    display: "",
                                    marginLeft: "-15%",
                                    zIndex: 999,
                                    color: "whitesmoke",
                                    opacity: hover ? "80%" : "40%"
                                }}
                                   onMouseEnter={navHover}
                                   onMouseLeave={navNoHover}
                                   onClick={next}></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className='owl-thumbs'
                    style={{backgroundSize: "100%", backgroundRepeat: "no-repeat"}}
                >
                    {images?.map((img, i) => {
                        return (
                            <button
                                key={i}
                                className={"owl-thumb-item " + (i === index ? "active" : "")}
                                onClick={() => setIndex(i)}
                            >
                                <img src={process.env.REACT_APP_API_URL + img} alt={`i${i}`}/>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
