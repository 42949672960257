import {useEffect, useState} from "react";
import {GetStatisticsInfo} from "../../services/DashboardService";
import LoadingGearSpinner from "../LoadingGearSpinner";

export default function InfoCards() {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<null | string>(null);
    const [stat, setStat] = useState<StatisticsInfo>();

    const getStatisticsData = () => {
        setLoading(true);
        GetStatisticsInfo().then(({data, status, message}) => {
            if (status === 200) {
                setStat(data);
            } else {
                setError(message);
            }
        }).catch((e) => {
            setError(e);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        getStatisticsData();
    }, []);
    return (
        <>
            {
                error !== null
                    ? error
                    : loading
                        ? <LoadingGearSpinner/>
                        : <div className="row">
                            <div className="col-lg-3 col-6 mb25 order-sm-1">
                                <div className="card p-4 rounded-5">
                                    <div className="symbol mb40">
                                        <i className="fa id-color fa-2x fa-car"></i>
                                    </div>
                                    <span className="h1 mb0">{stat?.carCount}</span>
                                    <span className="text-gray">Avtomobillər (Aktiv)</span>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 mb25 order-sm-1">
                                <div className="card p-4 rounded-5">
                                    <div className="symbol mb40">
                                        <i className="fa id-color fa-2x fa-list"></i>
                                    </div>
                                    <span className="h1 mb0">{stat?.carTypeCount}</span>
                                    <span className="text-gray">Ban növləri</span>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 mb25 order-sm-1">
                                <div className="card p-4 rounded-5">
                                    <div className="symbol mb40">
                                        <i className="fa id-color fa-2x fa-address-card"></i>
                                    </div>
                                    <span className="h1 mb0">{stat?.garageCount}</span>
                                    <span className="text-gray">Ünvanlar</span>
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 mb25 order-sm-1">
                                <div className="card p-4 rounded-5">
                                    <div className="symbol mb40">
                                        <i className="fa id-color fa-2x fa-calendar-check-o"></i>
                                    </div>
                                    <span className="h1 mb0">{stat?.orderCount}</span>
                                    <span className="text-gray">Sifarişlər</span>
                                </div>
                            </div>
                        </div>
            }
        </>
    );
}