import { useTranslation } from "react-i18next";


export default function Hero() {
  const { t } = useTranslation();
  return (
    <section
      id='section-hero'
      aria-label='section'
      className='full-height vertical-center'
      style={{
        backgroundImage: "url(/images/background/7.webp)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='spacer-double sm-hide'></div>
          <div className='col-lg-6'>
            <h4>
              <span className='id-color'>{t("hero.title")}</span>
            </h4>
            <div className='spacer-10'></div>
            <h1>{t("hero.subtitle")}</h1>
            <p className='lead'>{t("hero.text")}</p>
            <a className='btn-main' href='/cars'>
              {t("hero.button")}
            </a>
            {/* &nbsp;&nbsp;&nbsp;
            <a className='btn-main btn-black' href='/#'>
              Tətbiqi yüklə
            </a>*/}
          </div>

          <div className='col-lg-6'>
            <img
              src='/images/misc/car-2_50.webp'
              className='img-fluid'
              alt=''
              loading={"lazy"}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
