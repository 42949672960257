import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import { useAppSelector } from "../../../utils/Hooks";
import { settingSelector } from "../../../redux/stores/SiteReducer";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import { useTranslation } from "react-i18next";

export default function Agreement() {
  const { t } = useTranslation();
  const { setting, loading, error } = useAppSelector(settingSelector);
  const [post, setPost] = useState("");

  useEffect(() => {
    function getPost() {
      fetch(setting?.termsMdFileUrl)
        .then((response) => response.text())
        .then((text) => setPost(text))
        .catch((error) => console.error(error));
    }

    getPost();
  }, [setting?.termsMdFileUrl, post]);
  return (
    <>
      <Helmet>
        <title>RegesRent.Az | {t("agreement_page.title")}</title>
        <meta name='description' content={setting?.about} />
        <meta name='keywords' content={setting?.keywords} />
      </Helmet>
      {error === null ? (
        loading === true ? (
          <LoadingGearSpinner />
        ) : (
          <div className='no-bottom no-top' id='content'>
            <div id='top'></div>
            <section id='subheader' className='jarallax text-light'>
              <div className='center-y relative text-center'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 text-center mt-2'>
                      <h1 className='text-black'>
                        {t("agreement_page.title")}
                      </h1>
                    </div>
                    <div className='clearfix'></div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className='container'>
                <div className='row g-5'>
                  <div className='col-lg-12'>
                    <Markdown>{post}</Markdown>
                  </div>
                </div>
                <div className='spacer-double'></div>
              </div>
            </section>
          </div>
        )
      ) : (
        error
      )}
    </>
  );
}
