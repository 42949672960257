import { useTranslation } from "react-i18next";

export default function Features() {
  const { t } = useTranslation();
  return (
    <section style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}>
      <div
        className='container'
        style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
      >
        <div
          className='row'
          style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
        >
          <div
            className='col-lg-3'
            style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
          >
            <h2>{t("features.title")}</h2>
            <div
              className='spacer-20'
              style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
            ></div>
          </div>
          <div
            className='col-md-3'
            style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
          >
            <i className='fa fa-check de-icon mb20'></i>
            <h4>{t("features.one.title")}</h4>
            <p>{t("features.one.text")}</p>
          </div>
          <div
            className='col-md-3'
            style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
          >
            <i className='fa fa-handshake-o de-icon mb20'></i>
            <h4>{t("features.two.title")}</h4>
            <p>{t("features.two.text")}</p>
          </div>
          <div
            className='col-md-3'
            style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
          >
            <i className='fa fa-credit-card de-icon mb20'></i>
            <h4>{t("features.three.title")}</h4>
            <p>{t("features.three.text")}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
