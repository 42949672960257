import { useState } from "react";
import { settingSelector } from "../../../redux/stores/SiteReducer";
import { useAppSelector } from "../../../utils/Hooks";
import TopBar from "../../Main/TopBar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t } = useTranslation();
  const { setting } = useAppSelector(settingSelector);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header
      className={
        "transparent header-light scroll-light has-topbar header-mobile nav-up " +
        (menuOpen ? "menu-open" : "")
      }
      style={menuOpen ? { height: "844px" } : {}}
    >
      <TopBar
        contact={setting?.contact}
        socialLinks={setting?.socialLinks}
        workDaysString={setting?.workDaysString}
      />
      <div
        className='container'
        style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
      >
        <div
          className='row'
          style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
        >
          <div
            className='col-md-12 mt-2'
            style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
          >
            <div
              className='de-flex sm-pt10'
              style={{ backgroundSize: "100%", backgroundRepeat: "no-repeat" }}
            >
              <div
                className='de-flex-col'
                style={{
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  className='de-flex-col'
                  style={{
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    id='logo'
                    style={{
                      backgroundSize: "100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <a href='/'>
                      <img className='logo-1' src={setting?.logoLight} alt='' />
                      <img className='logo-2' src={setting?.logoDark} alt='' />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className='de-flex-col header-col-mid'
                style={{
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <ul id='mainmenu'>
                  <li onClick={() => setMenuOpen(false)}>
                    <Link className='menu-item' to='/'>
                      {t("header.menu.home")}
                    </Link>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    <Link className='menu-item' to='/cars/'>
                      {t("header.menu.cars")}
                    </Link>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    <Link className='menu-item' to='/services/'>
                      {t("header.menu.services")}
                    </Link>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    <Link className='menu-item' to='/agreement/'>
                      {t("header.menu.agreement")}
                    </Link>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    <Link className='menu-item' to='/contact/'>
                      {t("header.menu.contact")}
                    </Link>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    <Link className='menu-item' to='/social/'>
                      {t("header.menu.social")}
                    </Link>
                  </li>
                  <li onClick={() => setMenuOpen(false)}>
                    <Link className='menu-item' to='/about/'>
                      {t("header.menu.about")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className='de-flex-col'
                style={{
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  className='menu_side_area'
                  style={{
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <span
                    id='menu-btn'
                    onClick={() => setMenuOpen(!menuOpen)}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
