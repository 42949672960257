import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../utils/Hooks";
import {getServices, serviceSelector} from "../../../redux/stores/ServiceReducer";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import toast from "react-hot-toast";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import {DeleteService, InsertService, UpdateService} from "../../../services/CarService";
import Logging from "../../../utils/Logging";
import {Modal} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";

export default function ServicesTable() {
    const {services, loading, error} = useAppSelector(serviceSelector);
    const dispatch = useAppDispatch();

    const [opened, {open, close}] = useDisclosure(false);

    const [name, setName] = useState("");

    const MySwal = withReactContent(Swal);

    const deleteHandler = (id: number) => {
        MySwal.fire({
            title: "Silmək istədiyinizdən əminsinizmi?",
            showCancelButton: true,
            cancelButtonText: "Xeyr",
            cancelButtonColor: "#f93154",
            confirmButtonColor: "#00b74a",
            confirmButtonText: "Bəli",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteService(id).then((res) => {
                    if (res.status === 200) {
                        toast.success(res.message)
                    } else {
                        toast.error(res.message);
                    }
                })
                    .catch(e => Logging.error(e));
            }
        })
    }
    const editHandler = (service: Service) => {
        MySwal.fire({
            title: service.id + " id-li xidməti düzəlt.",
            showCancelButton: true,
            cancelButtonText: "İmtina",
            cancelButtonColor: "#f93154",
            confirmButtonColor: "#00b74a",
            confirmButtonText: "Tamamla",
            input: 'text',
            inputValue: service.name
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedService: Service = {
                    id: service.id,
                    isActive: true,
                    name: MySwal.getInput()?.value || ""
                }
                UpdateService(updatedService).then((res) => {
                    if (res.status === 200) {
                        toast.success(res.message)
                    } else {
                        toast.error(res.message);
                    }
                })
                    .catch(e => Logging.error(e));
            }
        })
    }
    const addService = async (e: any) => {
        e.preventDefault();
        const service = {
            name: name,
            isActive: true
        };

        await toast.promise(
            InsertService(service as Service),
            {
                loading: 'Göndərilir...',
                success: <b>Əlavə edildi.</b>,
                error: <b>Xəta baş verdi.</b>,
            }
        );
        close();
    }


    useEffect(() => {
        function fetchData() {
            dispatch(getServices());
        }

        fetchData();
    }, []);
    return (
        <>
            <Modal opened={opened} onClose={close} title={<h4>Əlavə et</h4>} withCloseButton={true} size="lg" centered
                   yOffset={50}>
                <form onSubmit={addService}>
                    <div className='spacer-10'></div>
                    <div className='row'>
                        <div className='col-lg-12 mb20'>
                            <h5>Adı</h5>
                            <input
                                type='text'
                                className='form-control'
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <button className='btn-main btn-fullwidth' type='submit'>
                        Tamamla
                    </button>
                    <div className='clearfix'></div>
                </form>
            </Modal>
            <div className="card p-4 rounded-5 mb25">
                <h4>Xidmətlər</h4>
                <button onClick={open} className='btn btn-success'>Yenisini əlavə et</button>
                {
                    error !== null ? error :
                        loading === true ? <LoadingGearSpinner/> :
                            <table className="table table-hover de-table">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">ID</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Adı</span>
                                    </th>
                                    <th scope="col">
                                        <span className="text-uppercase fs-12 text-gray">Əməliyyatlar</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    services?.map((service) => {
                                        return (<tr key={service?.id}>
                                            <td>
                                                <span className="d-lg-none d-sm-block">ID</span>
                                                <div className="badge bg-gray-100 text-dark">#{service?.id}</div>
                                            </td>
                                            <td>
                                                <span className="d-lg-none d-sm-block">Adı</span>
                                                <span className="bold">{service?.name}</span>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-sm badge rounded-pill bg-success"
                                                    onClick={(e) => editHandler(service)}>Düzəlt
                                                </button>
                                                <button
                                                    className="btn btn-sm badge rounded-pill bg-danger ml10"
                                                    onClick={(e) => deleteHandler(service?.id)}>Sil
                                                </button>
                                            </td>
                                        </tr>)
                                    })
                                }
                                </tbody>
                            </table>
                }
            </div>
        </>
    );
}