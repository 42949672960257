import axios from "../utils/AxiosInstance";


export async function Signin(data: FormData) {
    return axios
        .post("/auth", data)
        .then((response): ResponseModel<User> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<User> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function Signout() {
    return axios
        .get("/auth/logout")
        .then((response): ResponseModel<User> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<User> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}

export async function GetUserById() {
    return axios
        .get(`/auth/getuser`)
        .then((response): ResponseModel<User> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        })
        .catch(({response}): ResponseModel<User> => {
            return {
                data: response.data,
                status: response.status,
                message: response.statusText
            };
        });
}