import { Link } from "react-router-dom";
import { Carousel } from "@mantine/carousel";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { useTranslation } from "react-i18next";

export default function CarListItem({
  id,
  name,
  person,
  door,
  carType,
  price,
  images,
}: Car) {
  const { t } = useTranslation();
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  const slides = images.map((image, index) => {
    return (
      <Carousel.Slide key={image.id}>
        <img
          src={process.env.REACT_APP_API_URL + image.url}
          className='img-fluid'
          alt={name}
          key={image.id}
        />
      </Carousel.Slide>
    );
  });

  return (
    <div className='de-item mb30'>
      <div className='d-img'>
        <Carousel
          draggable={false}
          withControls={true}
          withIndicators
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          //loop
        >
          {slides}
        </Carousel>
      </div>
      <div className='d-info'>
        <div className='d-text'>
          <h4>{name}</h4>
          <div className='d-atr-group'>
            <span className='d-atr'>
              <img src='/images/icons/1.svg' alt='person' loading={"lazy"} />
              {person}
            </span>
            <span className='d-atr'>
              <img src='/images/icons/3.svg' alt='door' loading={"lazy"} />
              {door}
            </span>
            <span className='d-atr'>
              <img src='/images/icons/4.svg' alt='type' loading={"lazy"} />
              {carType?.name}
            </span>
          </div>
          <div className='d-price'>
            {t("carlist.daily")} <span>{price} AZN</span>
            <Link className='btn-main' to={`/car/${id}`}>
              {t("carlist.show_more")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
