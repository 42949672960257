import Input from "../Inputs/Input";
import React, {useEffect, useState} from "react";

interface SpecificationTableProps {
    specifications: Specification[];
    onChange: (specifications: Specification[]) => void;
}

export default function SpecificationTable({specifications, onChange}: SpecificationTableProps) {
    const [title, setTitle] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [id, setId] = useState<number>(0);
    const handleSpecTable = (e: any, i: number) => {
        let clone = [...specifications];
        let id = clone[i].id;
        let title = clone[i].title;
        let value = clone[i].value;

        if (e.target.name === 'title') {
            title = e.target.value;
        } else {
            value = e.target.value;
        }

        clone[i] = {id, title, value};
        onChange([...clone]);
    };
    const deleteRowHandle = (e: any, id: number) => {
        e.preventDefault();
        const newSpecs = specifications.filter(spec => spec.id !== id);
        onChange([...newSpecs]);
    }
    const addHandler = (e: any) => {
        e.preventDefault();
        onChange([...specifications, {id, title, value}]);
        setTitle("");
        setValue("");
    }

    useEffect(() => {
        const specIds = specifications?.map(item => item.id);
        const lastId = (specIds === undefined || specIds === null || specIds?.length === 0) ? 0 : Math.max(...specIds);
        setId(lastId + 1);
    }, [specifications]);
    return (<>
        <div className="card p-4 rounded-5 mb25">
            <table className="table table-sm">
                <thead>
                <tr>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray">ID</span>
                    </th>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray">Adı</span>
                    </th>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray">Dəyəri</span>
                    </th>
                    <th scope="col"><span className="text-uppercase fs-12 text-gray"></span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    specifications?.map((spec: Specification, index) => (
                        <tr key={spec.id}>
                            <td>
                                <span className="d-lg-none d-sm-block">ID</span>
                                <div className="badge bg-gray-100 text-dark">#{spec.id}</div>
                                <input type="hidden" value={spec.id} name="id"/>
                            </td>
                            <td>
                                <span className="d-lg-none d-sm-block">Adı</span>
                                <Input name={`title`} label=''
                                       value={spec.title} type='text'
                                       onChange={(e: any) => handleSpecTable(e, index)}/>
                            </td>
                            <td>
                                <span className="d-lg-none d-sm-block">Dəyəri</span>
                                <Input name={`value`} label=''
                                       value={spec.value} type='text'
                                       onChange={(e: any) => handleSpecTable(e, index)}/>
                            </td>
                            <td>
                                <span className="d-lg-none d-sm-block"></span>
                                <button
                                    onClick={(e) => deleteRowHandle(e, spec.id)}
                                    className="btn btn-sm badge rounded-pill bg-danger mt-3"><i
                                    className='fa fa-trash'></i>
                                </button>
                            </td>
                        </tr>
                    ))
                }
                <tr>
                    <td>
                        <span className="d-lg-none d-sm-block">ID</span>
                        <div className="badge bg-gray-100 text-dark">#{id}</div>
                        <input type="hidden" value="0" name="id"/>
                    </td>
                    <td>
                        <span className="d-lg-none d-sm-block">Adı</span>
                        <Input name='title' label=''
                               value={title} type='text'
                               onChange={(e: any) => setTitle(e.target.value)}/>
                    </td>
                    <td>
                        <span className="d-lg-none d-sm-block">Dəyəri</span>
                        <Input name='value' label=''
                               value={value} type='text'
                               onChange={(e: any) => setValue(e.target.value)}/>
                    </td>
                    <td>
                        <span className="d-lg-none d-sm-block"></span>
                        <button
                            onClick={addHandler}
                            className="btn btn-sm badge rounded-pill bg-success mt-3">
                            <i className='fa fa-plus'></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </>)
}