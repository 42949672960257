interface InputProps {
    image: Image;
    onRemove?: any;
}

const styles = {
    img: {borderRadius: "5px", border: "5px #ddd", margin: 5}
}

export default function ImageHolder({image, onRemove}: InputProps) {
    return (<>
        <div style={{position: "relative", width: 100, height: 100, display: "flex"}} key={image.id}>
            <label htmlFor={`image${image.id}`}>
                <img src={process.env.REACT_APP_API_URL + image.url} alt={`i${image.id}`} width={100} height={100}
                     style={styles.img}/>
            </label>
            <i className="fa fa-times-circle"
               style={{
                   position: "absolute",
                   top: "-3px",
                   right: "-9px",
                   color: "red",
                   cursor: "pointer"
               }}
               onClick={onRemove}></i>
        </div>
    </>);
}