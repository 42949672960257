import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../utils/Hooks";
import React, {useEffect, useState} from "react";
import Input from "../../../components/Inputs/Input";
import Checkbox from "../../../components/Inputs/Checkbox";
import toast from "react-hot-toast";
import LoadingGearSpinner from "../../../components/LoadingGearSpinner";
import {GetCarTypeById, UpdateCarType} from "../../../services/CarService";
import Logging from "../../../utils/Logging";

export default function EditCarType() {
    const {id} = useParams();

    const [type, setType] = useState<CarType>({} as CarType);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const dispatch = useAppDispatch();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log(type)
        UpdateCarType(type)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.message)
                } else {
                    toast.error(res.message);
                }
            })
            .catch(e => Logging.error(e));
    }

    useEffect(() => {
        function fetchData() {
            setType({} as CarType);
            setLoading(true);
            setError(null);
            let typeId = id as string;
            GetCarTypeById(typeId)
                .then(({data}) => {
                    setType(data);
                })
                .catch(e => {
                    setError(e)
                    Logging.error(e)
                })
                .finally(() => {
                    setLoading(false);
                })
        }

        fetchData();
    }, [dispatch, id]);

    return (<>
        {error !== null ? toast.error(error) : loading ? <LoadingGearSpinner/> :
            <div className="card p-4  rounded-5">
                <div className="row">
                    <div className="col-lg-12">
                        <form id="form-create-item" className="form-border" onSubmit={handleSubmit}
                              encType='multipart/form-data'>
                            <div className="de_tab tab_simple">

                                <ul className="de_nav">
                                    <li className="active"><span>{type.name} (#{type.id})</span></li>
                                </ul>

                                <div className="de_tab_content">
                                    <div className="tab-1">
                                        <div className="row">
                                            <div className="col-lg-6 mb20">
                                                <Input id='name' name='name' label='Adı'
                                                       value={type?.name} type='text'
                                                       onChange={(e: any) => setType({
                                                           ...type,
                                                           name: e.target.value
                                                       })}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-sm-20">
                                            <Checkbox
                                                label='Saytda görünsün'
                                                id="notif-item-sold"
                                                name='isActive'
                                                checked={type?.isActive}
                                                onChange={(e: any) => setType({...type, isActive: e.target.checked})}
                                                infoText='Bu tipin saytdan görünüb görünməməsini təmin edir.'/>
                                            <div className="spacer-20"></div>
                                        </div>
                                        <div className="spacer-20"></div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" id="submit" className="btn-main">Tamamla</button>
                        </form>
                    </div>
                </div>
            </div>
        }

    </>);
}