import { useTranslation } from "react-i18next";


export default function Error404() {
  const { t } = useTranslation();
  return (
    <>
      <div className='no-bottom no-top' id='content'>
        <div id='top'></div>
        <div className='no-bottom no-top' id='content'>
          <div id='top'></div>
          <section
            id='section-hero'
            className='jarallax text-light pt50 pb50 vertical-center'
            aria-label='section'
            style={{
              backgroundImage: "url(/images/background/11.webp)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "bottom",
            }}
          >
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-lg-6'>
                  <h1>{t("error_page.title")}</h1>
                  <p>{t("error_page.text")}</p>
                  <a href='/' className='btn-main'>
                    {t("error_page.button")}
                  </a>
                  <div className='spacer-10'></div>
                </div>
                <div className='col-lg-6 text-center'>
                  <h1 className='s2'>
                    <span className='c1'>404</span>
                    <span className='spacer-single'></span>
                    <span className='c3'>{t("error_page.title")}</span>
                  </h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
