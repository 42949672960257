interface InputProps {
    label: string;
    type: string;
    name: string;
    id?: string;
    placeholder?: string;
    required?: boolean;
    value: string | number;
    autoComplete?: 'on' | 'off';
    onChange:any;
    className?:any;
}

export default function Input({label, type, name, id, placeholder, required = false, value = "", autoComplete = "off",onChange,className = "form-control"}: InputProps) {
    return (<>
        <h5>{label}</h5>
        <input
            id={id}
            type={type}
            name={name}
            required={required}
            autoComplete={autoComplete}
            className={className}
            placeholder={placeholder}
            value={value}
            onChange={onChange}/>
    </>);
}