import { useTranslation } from "react-i18next";
import {
  settingSelector,
  toggleLang,
  toggleLangChanger,
} from "../../redux/stores/SiteReducer";
import { useAppDispatch, useAppSelector } from "../../utils/Hooks";

export default function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { langChanger, langList, lang } = useAppSelector(settingSelector);

  const changeHandler = (lang: any) => {
    // localStorage.setItem("reges_lang", lang);
    dispatch(toggleLangChanger(langChanger));
    dispatch(toggleLang(lang));
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <select
        className='topbar-widget'
        style={{
          cursor: "pointer",
          backgroundColor: "transparent",
          border: 0,
          borderLeft: "1px solid #2C323E",
          color: "white",
          paddingLeft: "10px",
        }}
        onChange={(e) => changeHandler(e.target.value)}
        value={lang}
      >
        {langList.map((lng: string) => {
          return (
            <option
              style={{
                cursor: "pointer",
                backgroundColor: "transparent",
                border: 0,
                color: "black",
              }}
              key={lng}
              value={lng}
            >
              {lng}
            </option>
          );
        })}
      </select>
    </>
  );
}
