import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../utils/Hooks";
import {
  carSelector,
  getAllCars,
  getTypes,
} from "../../../redux/stores/CarReducer";
import { GetAllCarBrands } from "../../../services/CarService";
import Logging from "../../../utils/Logging";
import { useTranslation } from "react-i18next";

export default function SideFilterBar() {
  const { t } = useTranslation();
  const { types } = useAppSelector(carSelector);
  const dispatch = useAppDispatch();

  const [type, setType] = useState<string>("0");
  const [manufacture, setManufacture] = useState<string | null>(null);
  const [manufactures, setManufactures] = useState<string[] | null>(null);

  const getBrands = () => {
    GetAllCarBrands()
      .then(({ data }) => {
        setManufactures(data);
      })
      .catch((err) => {
        Logging.error(err);
      });
  };

  const clearFilters = () => {
    dispatch(getAllCars(null));
  };

  const searchHandler = () => {
    dispatch(getAllCars({ type, manufacture }));
  };

  useEffect(() => {
    dispatch(getTypes());
    getBrands();
  }, []);
  return (
    <div className='item_filter_group'>
      <h4>{t("filters.filters")}</h4>
      <div className='de_form'>
        <div className='de_checkbox'>
          <label>{t("filters.type")} :</label>
          <select
            name='type'
            id='type'
            className='form-control'
            onChange={(e) => setType(e.currentTarget.value)}
          >
            <option value='0' key={0}></option>
            {types?.map((type: CarType) => {
              return (
                <option value={type.id} key={type.id}>
                  {type.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className='de_checkbox'>
          <label>{t("filters.manufacture")} :</label>
          <select
            name='manufacture'
            id='type'
            className='form-control'
            onChange={(e) => setManufacture(e.currentTarget.value)}
          >
            <option value=' ' key={0}></option>
            {manufactures?.map((man, index) => {
              return (
                <option value={man} key={index}>
                  {man}
                </option>
              );
            })}
          </select>
        </div>

        <div className='de_checkbox'>
          <button
            className='btn-main'
            style={{ marginTop: "15px", width: "100%" }}
            onClick={searchHandler}
          >
            {t("filters.search")}
          </button>
          <button
            className='btn-main'
            style={{ marginTop: "15px", width: "100%" }}
            onClick={clearFilters}
          >
            {t("filters.reset")}
          </button>
        </div>
      </div>
    </div>
  );
}
