import { useTranslation } from "react-i18next";
import { settingSelector } from "../../../redux/stores/SiteReducer";
import { useAppSelector } from "../../../utils/Hooks";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  const { setting } = useAppSelector(settingSelector);
  return (
    <footer className='text-light'>
      <div className='container'>
        <div className='row g-custom-x'>
          <div className='col-lg-3'>
            <div className='widget'>
              <h5>{t("footer.about")}</h5>
              <p>{setting?.about}</p>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='widget'>
              <h5>{t("footer.contact")}</h5>
              <address className='s1'>
                <span>
                  <i className='id-color fa fa-map-marker fa-lg'></i>
                  {setting?.contact?.officeAddress}
                </span>
                <span>
                  <i className='id-color fa fa-phone fa-lg'></i>
                  {setting?.contact?.phone}
                </span>
                <span>
                  <i className='id-color fa fa-envelope fa-lg'></i>
                  <a href={`mailto:${setting?.contact?.email}`}>
                    {setting?.contact?.email}
                  </a>
                </span>
                {setting?.catalogUrl !== undefined ? (
                  <span>
                    <i className='id-color fa fa-file-pdf-o fa-lg'></i>
                    <a href={setting?.catalogUrl}>{t("footer.catalog")}</a>
                  </span>
                ) : null}
              </address>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='widget'>
              <h5>{t("footer.social")}</h5>
              <div className='social-icons'>
                {setting?.socialLinks?.map((item: SocialLink) => (
                  <a href={item.link} key={item.id}>
                    <i className={item.icon + " fa-lg"}></i>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='subfooter'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='de-flex'>
                <div className='de-flex-col'>
                  <a href='/'>
                    Copyright 2023 - Rentaly by Mikro İnformasiya MMC
                  </a>
                </div>
                <ul className='menu-simple'>
                  <li>
                    <Link to='/agreement/'>{t("footer.agreement")}</Link>
                  </li>
                  <li>
                    <a href='/#'>{t("footer.terms")}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
