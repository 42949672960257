import {Chalk} from 'chalk';

const customChalk = new Chalk({level: 3});

export default class Logging {
    public static log = (args: any) => this.info(args);
    public static info = (args: any) =>
        console.log(
            customChalk.blue(`[${new Date().toLocaleString()}][INFO]`),
            typeof args === "string" ? customChalk.blueBright(args) : args
        );
    public static success = (args: any) =>
        console.log(
            customChalk.green(`[${new Date().toLocaleString()}][INFO]`),
            typeof args === "string" ? customChalk.greenBright(args) : args
        );

    public static warn = (args: any) =>
        console.log(
            customChalk.yellow(`[${new Date().toLocaleString()}][WARN]`),
            typeof args === "string" ? customChalk.yellowBright(args) : args
        );

    public static error = (args: any) =>
        console.log(
            customChalk.red(`[${new Date().toLocaleString()}][ERR]`),
            typeof args === "string" ? customChalk.redBright(args) : args
        );
}
