import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    GetAllCars,
    GetAllCarsEvenInactive,
    GetCarById,
    GetCarTypes,
    GetTopCars
} from "../../services/CarService";

interface InitState {
    cars: Array<Car>;
    topCars: Array<Car>;
    car: Car;
    types: Array<CarType>;
    loading: boolean;
    error: any;
}

const initialState: InitState = {
    cars: [] as Car[],
    topCars: [] as Car[],
    car: {} as Car,
    types: [] as CarType[],
    loading: false,
    error: null,
};

//methods
export const getAllCars = createAsyncThunk("getAllCars", async (params?: any) => {
    try {
        return await GetAllCars(params);
    } catch (ex: any) {
        return ex.response.data;
    }
});

export const getTopCars = createAsyncThunk("getTopCars", async () => {
    try {
        return await GetTopCars(9);
    } catch (ex: any) {
        return ex.response.data;
    }
});

export const getAllCarsEvenInactive = createAsyncThunk("getAllCarsEvenInactive", async (params?: any) => {
    try {
        return await GetAllCarsEvenInactive(params);
    } catch (ex: any) {
        return ex.response.data;
    }
});

export const getCarById = createAsyncThunk("getCarById", async (id: string) => {
    try {
        return await GetCarById(id);
    } catch (ex: any) {
        return ex.response.data;
    }
});

export const getTypes = createAsyncThunk("getTypes", async () => {
    try {
        return await GetCarTypes();
    } catch (ex: any) {
        return ex.response.data;
    }
});

export const carSlice = createSlice({
    name: "carSlice",
    initialState,
    reducers: {
        setCar: (state, action) => {
            state.car = action.payload;
        }
    },
    extraReducers: (builder) => {
        //#region GetAllCars
        builder.addCase(getAllCars.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllCars.fulfilled, (state, action) => {
            state.cars = action.payload.data;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getAllCars.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        //#endregion

        //#region GetTopCars
        builder.addCase(getTopCars.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getTopCars.fulfilled, (state, action) => {
            state.topCars = action.payload.data;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getTopCars.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        //#endregion

        //#region GetAllCarsEvenInactive
        builder.addCase(getAllCarsEvenInactive.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllCarsEvenInactive.fulfilled, (state, action) => {
            state.cars = action.payload.data;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getAllCarsEvenInactive.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        //#endregion

        //#region GetCarById
        builder.addCase(getCarById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getCarById.fulfilled, (state, action) => {
            state.car = action.payload.data;
            state.error = null;
            state.loading = false;
        });
        builder.addCase(getCarById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        //#endregion
        //#region GetTypes
        builder.addCase(getTypes.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getTypes.fulfilled, (state, action) => {
            state.types = action.payload.data;
            state.loading = false;
        });
        builder.addCase(getTypes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        //#endregion
    },
});

export const {setCar} = carSlice.actions;
export const carSelector = (state: RootState) => state.carReducer;
export default carSlice.reducer;
