import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { GetSiteSetting } from "../../services/SiteService";

interface InitState {
  setting: SiteSetting;
  loading: boolean;
  error: any;
  preloader: boolean;
  langChanger: boolean;
  langList: string[];
  lang: string;
}

const initialState: InitState = {
  setting: {} as SiteSetting,
  loading: false,
  error: null,
  preloader: false,
  langChanger: false,
  langList: ["AZ", "TR", "EN", "RU"],
  lang: localStorage.getItem("reges_lang") || "AZ",
};

//methods
export const getSettings = createAsyncThunk("getSettings", async () => {
  try {
    return await GetSiteSetting();
  } catch (ex: any) {
    return ex.response.data;
  }
});

export const settingSlice = createSlice({
  name: "settingSlice",
  initialState,
  reducers: {
    setPreloader: (state, action) => {
      state.preloader = action.payload;
    },
    toggleLangChanger: (state, action) => {
      state.langChanger = !state.langChanger;
    },
    toggleLang: (state, action) => {
      state.lang = action.payload;
      localStorage.setItem("reges_lang", action.payload);
    },
  },
  extraReducers: (builder) => {
    //GetSiteSettings
    builder.addCase(getSettings.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.preloader = true;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.setting = action.payload.data[0];
      state.loading = false;
      state.preloader = false;
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.preloader = false;
    });
  },
});

export const { toggleLang, toggleLangChanger } = settingSlice.actions;
export const settingSelector = (state: RootState) => state.settingReducer;
export default settingSlice.reducer;
