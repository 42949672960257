import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {GetGarages} from "../../services/CarService";

interface InitState {
  loading: boolean;
  error: any;
  garages: Array<Garage>;
}

const initialState: InitState = {
  loading: false,
  error: null,
  garages: [] as Garage[],
};

//methods
export const getGarages = createAsyncThunk("getGarages", async () => {
  try {
    return await GetGarages();
  } catch (ex: any) {
    return ex.response.data;
  }
});

export const garageSlice = createSlice({
  name: "garageSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //GetGarages
    builder.addCase(getGarages.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGarages.fulfilled, (state, action) => {
      state.garages = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getGarages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// export const {} = garageSlice.actions;
export const garageSelector = (state: RootState) => state.garageReducer;
export default garageSlice.reducer;
